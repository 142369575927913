const IconIos = ({ className }: { className?: string }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        className="fill-[rgba(255,255,255,0.6)] group-hover:fill-[rgba(255,255,255,0.9)] transition-all"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1464 6.01321C23.1943 6.60254 23.3243 8.20045 21.7496 9.95453C20.6104 11.2022 19.2272 11.9333 17.7125 11.8246C17.5842 10.3186 18.182 8.98278 19.199 7.9024C20.1222 6.89542 21.7433 6.07633 23.1453 6L23.1464 6.01321ZM27.9459 14.2787C25.9931 15.3914 25.0261 16.9004 25.0386 18.9026C25.0386 18.9054 25.0385 18.9106 25.0383 18.918C25.0305 19.181 24.9389 22.2836 28.625 23.9141C27.9177 25.9222 25.4893 30.3816 22.6634 30.4198C21.8719 30.4198 21.2391 30.191 20.5867 29.9551C19.8925 29.7041 19.1761 29.4451 18.2226 29.4451C17.1919 29.4451 16.4156 29.7208 15.6883 29.979C15.0709 30.1983 14.4888 30.405 13.8163 30.4198C11.0155 30.4991 8.33038 25.5933 7.58557 23.5911C7.02852 22.0704 6.75 20.5937 6.75 19.1639C6.75 14.3257 10.1955 11.9477 13.4377 11.9007C14.3377 11.9007 15.3774 12.2534 16.2655 12.5547C16.9276 12.7792 17.5055 12.9752 17.8784 12.9752C18.1799 12.9752 18.6717 12.8048 19.2751 12.5958C20.3254 12.2319 21.7134 11.7509 23.0232 11.8479C25.2014 12.0035 26.8537 12.8108 27.9459 14.2787Z"
      />
    </svg>
  );
};

const IconAndorid = ({ className }: { className?: string }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        className="fill-[rgba(255,255,255,0.6)] group-hover:fill-[rgba(255,255,255,0.9)] transition-all"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5337 8.61286C9.18105 8.24294 10.0057 8.46785 10.3756 9.1152L12.9631 13.6433C12.9848 13.6813 13.0045 13.7199 13.0221 13.759C14.6371 13.0898 16.4057 12.7208 18.2596 12.7208C19.911 12.7208 21.4946 13.0136 22.9624 13.5506L25.4969 9.1152C25.8668 8.46785 26.6915 8.24294 27.3388 8.61286C27.9862 8.98277 28.2111 9.80742 27.8412 10.4548L25.3995 14.7276C29.0214 16.9421 31.5536 20.7905 31.9932 25.2602H4.52588C4.94612 20.9875 7.27852 17.2826 10.647 15.0299C10.6374 15.0145 10.628 14.9988 10.6189 14.9829L8.03136 10.4548C7.66145 9.80742 7.88636 8.98277 8.5337 8.61286ZM13.7317 20.135C13.7317 20.8495 13.1524 21.4288 12.4379 21.4288C11.7234 21.4288 11.1442 20.8495 11.1442 20.135C11.1442 19.4205 11.7234 18.8413 12.4379 18.8413C13.1524 18.8413 13.7317 19.4205 13.7317 20.135ZM24.0816 21.4288C24.7961 21.4288 25.3753 20.8495 25.3753 20.135C25.3753 19.4205 24.7961 18.8413 24.0816 18.8413C23.3671 18.8413 22.7878 19.4205 22.7878 20.135C22.7878 20.8495 23.3671 21.4288 24.0816 21.4288Z"
      />
    </svg>
  );
};

const IconMac = ({ className }: { className?: string }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        className="fill-[rgba(255,255,255,0.6)] group-hover:fill-[rgba(255,255,255,0.9)] transition-all"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 6.75C5.50736 6.75 4.5 7.75736 4.5 9V25.5C4.5 26.7426 5.50736 27.75 6.75 27.75H15L13.5 30.75H21.75L20.25 27.75H28.5C29.7426 27.75 30.75 26.7426 30.75 25.5V9C30.75 7.75736 29.7426 6.75 28.5 6.75H6.75ZM19.1295 13.4573C19.7673 12.7469 19.7146 12.0997 19.6952 11.8611L19.6948 11.8557C19.127 11.8866 18.4704 12.2184 18.0965 12.6262C17.6846 13.0637 17.4425 13.6047 17.4945 14.2147C18.108 14.2587 18.6682 13.9626 19.1295 13.4573ZM20.4616 17.0813C20.4565 16.2704 20.8482 15.6593 21.639 15.2086C21.1967 14.6141 20.5275 14.2871 19.6454 14.2241C19.1149 14.1849 18.5527 14.3796 18.1274 14.527C17.883 14.6117 17.6838 14.6807 17.5617 14.6807C17.4107 14.6807 17.1766 14.6013 16.9085 14.5104C16.5488 14.3884 16.1277 14.2455 15.7632 14.2455C14.4501 14.2646 13.0547 15.2276 13.0547 17.1871C13.0547 17.7662 13.1675 18.3642 13.3931 18.9801C13.6947 19.791 14.7822 21.7779 15.9166 21.7458C16.1889 21.7398 16.4247 21.656 16.6747 21.5672C16.9693 21.4626 17.2836 21.351 17.7011 21.351C18.0873 21.351 18.3774 21.4559 18.6586 21.5576C18.9228 21.6531 19.1791 21.7458 19.4996 21.7458C20.6441 21.7303 21.6276 19.9242 21.9141 19.1109C20.4212 18.4506 20.4583 17.194 20.4614 17.0875L20.4616 17.0813Z"
      />
    </svg>
  );
};

const IconPad = ({ className }: { className?: string }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        className="fill-[rgba(255,255,255,0.6)] group-hover:fill-[rgba(255,255,255,0.9)] transition-all"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 9.22581C7 7.99653 8.04467 7 9.33333 7H25.6667C26.9553 7 28 7.99653 28 9.22581V27.7742C28 29.0035 26.9553 30 25.6667 30H9.33333C8.04467 30 7 29.0035 7 27.7742V9.22581ZM15.75 26C15.3358 26 15 26.3358 15 26.75C15 27.1642 15.3358 27.5 15.75 27.5H19.25C19.6642 27.5 20 27.1642 20 26.75C20 26.3358 19.6642 26 19.25 26H15.75Z"
      />
    </svg>
  );
};

export default function DownLoadItem({ platform, className }: { platform: AppPlatform; className?: string }) {
  const Icon = ({ className }: { className?: string }) => {
    switch (platform) {
      case 'ios':
        return <IconIos className={className} />;
      case 'android':
        return <IconAndorid className={className} />;
      case 'mac':
        return <IconMac className={className} />;
      case 'pad':
        return <IconPad className={className} />;
    }
  };

  const nameList: Record<AppPlatform, string> = {
    ios: 'iphone版',
    android: 'Android版',
    mac: 'Mac版',
    pad: 'Pad版',
  };

  return (
    <div className={`flex flex-col items-center w-56px cursor-pointer ${className} group`}>
      <Icon />
      <p className="w-fit mt-3px mx-auto text-12px leading-17px group-hover:text-[rgba(255,255,255,0.9)] transition-colors">
        {nameList[platform]}
      </p>
    </div>
  );
}
