import Image from 'next/image';

import styles from './index.module.css';

import { VolTag } from '@/components';

interface IProps {
  playState: boolean;
  audioInfo: SongInfo;
  className?: string;
  onRouterPush: (no: string) => void;
}

export default function PlayerCardMeta({ playState, audioInfo, className, onRouterPush }: IProps) {
  return (
    <div className={className}>
      {/* 专辑封面区域 */}
      <div className="relative w-[340px] h-[246px]">
        {/* 阴影 */}
        <div
          className={`absolute left-0 bottom-0 w-[230px] h-[230px] rounded-[3px] bg-[rgba(0,0,0,0.08)]`}
          style={{
            boxShadow: '27px 10px 30px -12px rgba(0, 0, 0, 0.25)',
          }}
        />

        {/* 专辑封面 */}
        {audioInfo?.pic && (
          <Image
            width={246}
            height={246}
            unoptimized
            src={audioInfo.pic}
            alt="pic"
            className="absolute left-[16px] w-[246px] h-[246px] rounded-[3px] z-3"
            style={{ boxShadow: '27px 10px 30px -12px rgba(0, 0, 0, 0.25)' }}
          />
        )}

        {/* CD */}
        <div
          className={`absolute right-0 top-[13px] w-[220px] h-[220px] bg-[url(/img/audio-player/CD.png)] bg-cover bg-no-repeat z-2 ${styles.CD_animation} ${!playState && styles.CD_animation_pause}`}
        />
      </div>

      {/* 歌曲信息 */}
      <div>
        {/* 期刊号 */}
        {audioInfo?.journalNo && <VolTag no={audioInfo.journalNo} onClick={() => onRouterPush(audioInfo.journalNo)} />}

        {/* 歌曲名 */}
        <p className="w-[340px] mt-[12px] text-[20px] leading-[28px] text-base text-overflow">
          {audioInfo?.title ?? ''}
        </p>

        {/* 歌手/专辑 */}
        {audioInfo?.artist && audioInfo?.album && (
          <p className="w-[340px] text-[13px] leading-[18px] mt-[2px] text-[rgba(0,0,0,0.7)] text-overflow">{`${audioInfo.artist}/${audioInfo.album}`}</p>
        )}
      </div>
    </div>
  );
}
