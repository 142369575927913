'use client';

import { useEffect, useState } from 'react';

import ChildCommentItem from './ChildCommentItem';

import { Pagination } from '@/components';
import { apiGetChildComment } from '@/services';

interface Props {
  parentId: string;
  /** 展开全部 */
  showAll: boolean;
  /** 最热门子评论 */
  topComment: Comment | undefined;
  /** 评论总数 */
  total: number;
  /** 新回复的评论 */
  newCommentList: Comment[];
}

export default function ChildCommentList({ parentId, showAll, topComment, total, newCommentList }: Props) {
  const [page, setPage] = useState<number>(0); // 页码
  const [ChildCommentList, setChildCommentList] = useState<Comment[]>([]);
  const pageSize = 10;

  const getComments = async () => {
    const res = await apiGetChildComment({ parentId, size: pageSize, page });
    if (res.code === 200) {
      const list = res.data.rows;
      if (page === 1) list.shift();
      setChildCommentList(list);
    }
    return res.code === 200;
  };

  useEffect(() => {
    if (page < 1) return;
    getComments();
  }, [page]);

  useEffect(() => {
    if (showAll) setPage(1);
  }, [showAll]);

  useEffect(() => {}, [newCommentList]);

  return (
    <div>
      {/* 用户新发送的评论 */}
      {newCommentList.map((comment) => (
        <ChildCommentItem className="mt-[12px]" key={comment._id} {...comment} />
      ))}

      {/* 最热门子评论 未展开全部评论时便已展示 */}
      {!!topComment?._id && <ChildCommentItem className="mt-[12px]" {...topComment} />}

      {/* 子评论，点击「展开全部评论」后展示 */}
      {showAll &&
        ChildCommentList.map((ChildComment) => (
          <ChildCommentItem key={ChildComment._id} className="mt-[12px]" {...ChildComment} />
        ))}

      {/* 分页器, total > 10 且展开回复 时展示 */}
      {showAll && total > 10 && (
        <Pagination
          type="client"
          total={total}
          pageSize={pageSize}
          current={page}
          onChange={(val) => setPage(val)}
          className="mt-[12px]"
          showPre
          showNext
          itemClassName="w-fit bg-none text-[#000] text-14px leading-20px hover:text-brand"
          activeClassName="text-brand"
          disabledClassName="hover:text-[#000]"
        />
      )}
    </div>
  );
}
