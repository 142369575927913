'use client';

import { usePathname } from 'next/navigation';

import FullFooter from './FullFooter';
import MiniFooter from './MiniFooter';

export default function Footer() {
  const pathName = usePathname();

  const showFullFooter = pathName === '/' || /^\/vol\//.test(pathName) || /^\/music\//.test(pathName); // 展示大 footer
  const showNoFooter = /^\/my\//.test(pathName); // 不展示 footer

  return (
    <footer id="footer_global" className={`${showNoFooter ? 'hidden' : 'block'} w-full bg-[rgba(0,0,0,0.9)]`}>
      {showFullFooter ? <FullFooter /> : <MiniFooter />}
    </footer>
  );
}
