/** 收藏期刊按钮 */

'use client';

import { useEffect, useState } from 'react';

import styles from './thumb.module.css';

interface Props {
  /** 是否显示文案 */
  showText?: boolean;
  /** 是否已激活 */
  active: boolean;
  count?: number;
  text?: string;
  /** 收藏类型  0:歌曲，1:期刊，2:关注，3:黑名单 */
  iconPosition?: 'left' | 'right';
  className?: string;
  textClassName?: string;
  /** 文字跟图标之间的间隔  单位px */
  gap?: number;
  onClick: () => Promise<boolean>;
}

export default function ThumbupButton({
  active,
  count = 0,
  text = '',
  iconPosition = 'left',
  gap = 9,
  className = '',
  textClassName = '',
  showText = false,
  onClick,
}: Props) {
  const [haveThumb, setHaveThumb] = useState<boolean>(false); // 收藏状态
  const [currentCount, setCurrentCount] = useState<number>(0); // 收藏数量
  const [animate, setAnimate] = useState<boolean>(false); // 动画状态

  /**
   * @desc 点赞/取消点赞 (先改变状态，后根据结果回退版本)
   */
  // const handleThumb = async (event: React.MouseEvent) => {
  //   event.stopPropagation();
  //   const state = haveThumb; // 收藏状态

  //   setHaveThumb(!state); // 更新收藏状态
  //   setCurrentCount((currentCount) => currentCount + (!state ? 1 : -1));

  //   const res = await onClick();
  //   // 如果请求失败，回退状态
  //   if (!res) {
  //     setHaveThumb(state);
  //     setCurrentCount((currentCount) => currentCount + (state ? 1 : -1));
  //   }
  // };

  // 点赞/取消点赞
  const handleThumb = async (event: React.MouseEvent) => {
    event.stopPropagation();

    const res = await onClick();
    const preState = haveThumb; // 旧状态

    if (res) {
      setAnimate(!preState);
      setHaveThumb(!haveThumb); // 更新收藏状态
      setCurrentCount((currentCount) => currentCount + (!haveThumb ? 1 : -1));
    }
  };

  useEffect(() => {
    setHaveThumb(active);
  }, [active]);

  useEffect(() => {
    setCurrentCount(count);
  }, []);

  return (
    <div
      className={`flex flex-row items-end gap-${gap}px w-fit h-24px  cursor-pointer group ${className}`}
      onClick={handleThumb}
    >
      {iconPosition === 'left' && <Icon haveThumb={haveThumb} animate={animate} />}
      {/* 文案 */}
      {!!(showText && currentCount) && (
        <p
          className={` pb-2px ${haveThumb ? 'text-brand' : 'text-[rgba(0,0,0,0.4)] group-hover:text-base'} text-13px leading-18.2px ${textClassName} ${styles.text} transition-colors-500`}
        >
          {`${currentCount || ''}${text || ''}`}
        </p>
      )}
      {/* 图标 */}
      {iconPosition === 'right' && <Icon haveThumb={haveThumb} animate={animate} />}
    </div>
  );
}

function Icon({ haveThumb, animate }: { haveThumb: boolean; animate: boolean }) {
  return (
    <div className={styles['thumb-icon']}>
      <input type="checkbox" readOnly className={`${styles.checkbox}`} checked={haveThumb} />

      <svg
        className={`${styles['thumb-svg']}  ${animate && styles.animate}`}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={`${styles['thumb-path']} group-hover:stroke-[#000] transition-stroke`}
          d="M8.74229 18.4016H6.82182C6.48226 18.4016 6.15661 18.2667 5.9165 18.0266C5.67639 17.7865 5.5415 17.4609 5.5415 17.1213V12.6402C5.5415 12.3006 5.67639 11.975 5.9165 11.7349C6.15661 11.4948 6.48226 11.3599 6.82182 11.3599H8.74229M13.2234 10.0796V7.51893C13.2234 7.00958 13.0211 6.5211 12.6609 6.16094C12.3007 5.80079 11.8123 5.59845 11.3029 5.59845L8.74229 11.3599V18.4016H15.9633C16.272 18.4051 16.5717 18.2969 16.8069 18.0969C17.0422 17.8969 17.1973 17.6187 17.2436 17.3134L18.127 11.5519C18.1549 11.3684 18.1425 11.1811 18.0907 11.0028C18.039 10.8246 17.9491 10.6597 17.8273 10.5197C17.7055 10.3796 17.5548 10.2677 17.3854 10.1917C17.2161 10.1157 17.0323 10.0775 16.8467 10.0796H13.2234Z"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
