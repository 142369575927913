'use client';

import { useShallow } from 'zustand/react/shallow';

import { LoginProvider } from '@/components/Login/LoginContext';

import HeaderAvatar from './HeaderAvatar';

import { Login } from '@/components';
import { useUserStore } from '@/store';

export default function Auth() {
  const userId = useUserStore(useShallow((state) => state.userInfo.id));

  return (
    <div>
      {!!userId ? (
        <HeaderAvatar className="ml-24px" />
      ) : (
        <LoginProvider>
          <Login />
        </LoginProvider>
      )}
    </div>
  );
}
