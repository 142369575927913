'use client';

/** 子评论输入框 */
import { useState, useRef, useEffect } from 'react';

import { debounce } from 'lodash';

import { Avatar, Button } from '@/components';

interface Props {
  nickName: string;
  avatar: string;
  className?: string;
  onSubmit: (value: string) => Promise<boolean>;
  onClose: () => void;
}

export default function ChildCommentForm({ nickName, avatar, className, onSubmit, onClose }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (!value.trim()) return;

    setLoading(true);
    const res = await onSubmit(value);
    if (res) {
      onClose();
    }

    setLoading(false);
  };

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  });

  return (
    <form className={`${className}`}>
      <div className={`flex flex-row items-center`}>
        <Avatar size={40} src={avatar} className="mr-[6px]" />
        <input
          ref={inputRef}
          type="text"
          disabled={loading}
          value={value}
          placeholder={` 回复 ${nickName}`}
          onChange={(e) => setValue(e.target.value)}
          className="w-[600px] h-[48px] px-[14px] rounded-[3px] bg-[#F2F3F7] text-[rgba(0,0,0,0.4)] text-[14px] leading-[19.6px]"
        />
      </div>

      <Button
        type="primary"
        disabled={loading || !value.trim()}
        className="w-[80px] h-[35px] my-[15px] rounded-[120px] text-[13px] leading-[18.2px]"
        onClick={debounce(handleSubmit)}
      >
        确定
      </Button>
    </form>
  );
}
