'use client';

import { useState, useContext } from 'react';

import { Dialog, DialogContent, DialogTrigger, DialogClose } from '@/components/ui/dialog';

import Footer from './Footer';
import { LoginContext } from './LoginContext';
import LoginPhone from './LoginPhone';
import LoginQrcode from './LoginQrcode';
import Tab from './Tab';

import { IconClose } from '@/components';

export default function Login() {
  const { state, dispatch } = useContext(LoginContext);
  const [currentTab, setCurrent] = useState<string>('qrCode');

  return (
    <Dialog open={state.showLogin} onOpenChange={(open) => dispatch({ type: 'SHOW_LOGIN', payload: open })}>
      <DialogTrigger asChild>
        <button
          id="header_login_button_for_gobal"
          className="w-[74px] h-[36px] ml-[40px] border-[#000] border-[1.5px] rounded-[60px] text-[17px] hover:bg-brand hover:border-brand hover:text-[#fff] "
        >
          登录
        </button>
      </DialogTrigger>

      <DialogContent
        className="w-400px h-430px pt-55px px-40px bg-#f2f3f7"
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <Tab value={currentTab} changeTab={(value: string) => setCurrent(value)} className="mt-5px" />

        <DialogClose className="absolute top-24px right-24px w-24px h-24px cursor-pointer outline-none">
          <IconClose />
        </DialogClose>

        <div>
          {currentTab === 'qrCode' && <LoginQrcode />}
          {currentTab === 'phone' && <LoginPhone />}
        </div>

        <Footer />
      </DialogContent>
    </Dialog>
  );
}
