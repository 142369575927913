/** 验证登录状态 */
import { setCookie, destroyCookie } from 'nookies';
import { parseCookies } from 'nookies';

// 离线验证
export const checkAuthOffline: () => boolean = () => {
  // cookie
  // const { token } = parseCookies();

  // store
  const userStorage: string = localStorage.getItem('user') || '{}';
  const userId = JSON.parse(userStorage || '{}')?.state?.userInfo?.id ?? '';

  return !!userId;
};

export const getAuthorization = async () => {
  const { token } = await parseCookies();
  return token || '';
  // if (!token) return '';
  // const exp = parseInt(expires ?? 0);
  // console.log(111, !isExpToken(exp));

  // if (!isExpToken(exp)) {
  //   return token;
  // } else {
  //   logout();
  //   return '';
  // return tokenRefresh();
  // token 过期了，重新登录请求然后给请求头设置好token
  // 获取token的方法等也可以根据authType的不同进行自定义设置
  // const res = await getToken("账号密码等");
  // setSession("local", tokenKey, res?.data);
  // authorization = res?.data;
  // }
};

export function setAccessToken({ token, expires }: { token: string; expires: string }) {
  setCookie(null, 'token', token, {
    maxAge: 7 * 24 * 60 * 60,
    path: '/',
  });

  setCookie(null, 'expires', expires, {
    maxAge: 7 * 24 * 60 * 60,
    path: '/',
  });
}

export function clearAccessToken() {
  destroyCookie(null, 'token', { path: '/' });
  destroyCookie(null, 'expires', { path: '/' });
}

export const isExpToken = (expTime: number) => {
  const expTimeStamp = expTime * 1000; // 转换为毫秒
  const nowTime = new Date().getTime(); // 获取当前时间戳
  return nowTime > expTimeStamp;
};
