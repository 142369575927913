import { MiniJournalCardList } from '@/components';

interface IProps {
  data: JournalInfo[];
  keyword: string;
  className?: string;
  onClose: () => void;
}

export default function Journal({ data, keyword, className, onClose }: IProps) {
  return (
    <div className={className}>
      <p className="mt-30px text-#000 text-14px leading-20px font-500">期刊</p>
      <MiniJournalCardList list={data} keyword={keyword} className="w-full mt-18px" onClick={onClose} />
    </div>
  );
}
