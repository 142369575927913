/**
 * 这里需要改一下，最好能在初始化的时候刷新一次 UserInfo
 * https://github.com/pmndrs/zustand/blob/main/docs/integrations/persisting-store-data.md#usage-in-nextjs
 */

import { produce } from 'immer';
import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';

import { apiGetMyUserInfo } from '@/services';

// interface TRequestLogin {
//   mobile: string;
//   mobileCheckCode: string;
//   deviceId: string;
// }

interface UserState {
  /** 登录框显示状态 */
  showLogin: boolean;
  /** 用户信息 */
  userInfo: UserInfo;
  /** 调用出登录框 */
  setShowLogin: (value: boolean) => void;
  /** 更新用户信息 */
  setUserInfo: (parmas: UserInfo) => void;
  /** 用户登录 */
  // userLogin: (params: TRequestLogin) => Promise<FetchResponse<string>>;
  /** 获取用户信息 */
  getUserInfo: () => Promise<FetchResponse<UserInfo>>;
}

const useUserStore = create<UserState>()(
  devtools(
    persist(
      (set) => {
        const initialUserInfo = {
          avatar: '',
          badgeList: [0],
          birthDay: '',
          commentReplyCount: 0,
          contributorRole: '',
          fansCount: 0,
          followCount: 0,
          haveNewMessage: false,
          id: '',
          ipLocation: '',
          journalCount: 0,
          mobile: '',
          nickName: '',
          relation: 0,
          sex: 0,
          signature: '',
          songCount: 0,
          thumbUpCount: 0,
        };

        const setUserInfo = (value: UserInfo) =>
          set(
            produce((state) => {
              state.userInfo = value;
            }),
          );

        const getUserInfo = async () => {
          const result = await apiGetMyUserInfo();
          if (result.code === 200) setUserInfo(result.data);
          return result;
        };

        return {
          userInfo: initialUserInfo,
          showLogin: false,
          setShowLogin: (value) =>
            set(
              produce((state) => {
                state.showLogin = value;
              }),
            ),
          setUserInfo,
          getUserInfo,
        };
      },
      {
        name: 'user',
        storage: createJSONStorage(() => localStorage),
      },
    ),
    {
      name: 'user', // redux-devtools 中的 store 名称
      enabled: process.env.NEXT_PUBLIC_NODE_ENV !== 'production', // 是否开启 redux-devtools
    },
  ),
);

export default useUserStore;
