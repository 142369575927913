import PaginationEllipsis from './PaginationEllipsis';
import PaginationItem from './PaginationItem';

interface Props {
  /**
   * 类型
   * client: 通过onChange传值
   * server: a标签
   */
  type: 'client' | 'server';
  /** 总页数 */
  total: number;
  /** 每页数量 */
  pageSize: number;
  /** 当前页数 */
  current: number;
  /** 是否加载中 */
  loading?: boolean;
  /** 最多显示的页码数量 */
  maxPageCount?: number;
  /** 样式 */
  className?: string;
  /** 展示上一页 */
  showPre?: boolean;
  /** 展示下一页 */
  showNext?: boolean;
  /** item 样式 */
  itemClassName?: string;
  /** 上一页样式 */
  preClassName?: string;
  /** 下一页样式 */
  nextClassName?: string;
  /** 激活状态样式 */
  activeClassName?: string;
  /** 不可用状态样式 */
  disabledClassName?: string;
  link?: string;
  onChange?: (page: number) => void;
}

export default function Pagination({
  type = 'server',
  total,
  pageSize,
  current,
  loading = false,
  maxPageCount = 7,
  className,
  showPre = false,
  showNext = false,
  itemClassName,
  preClassName,
  nextClassName,
  activeClassName,
  disabledClassName,
  link = '',
  onChange = () => {},
}: Props) {
  const currentPage = Number(current);
  const pageCount = Math.ceil(total / pageSize); // 总页数
  const startPage = Math.max(2, currentPage - Math.floor((maxPageCount - 3) / 2)); // 起始页
  const endPage = Math.min(pageCount - 1, startPage + maxPageCount - 3); // 结束页

  return (
    <ul className={`flex flex-row gap-[12px] ${className}`}>
      {/* 上一页 */}
      {showPre && (
        <PaginationItem
          className={preClassName || itemClassName}
          page={currentPage - 1}
          type={type}
          link={link}
          disabled={loading || currentPage <= 1}
          onClick={onChange}
          disabledClassName={disabledClassName}
        >
          上一页
        </PaginationItem>
      )}

      {/* 第一页 */}
      {pageCount > 1 && (
        <PaginationItem
          className={itemClassName}
          type={type}
          page={1}
          active={1 === currentPage}
          disabled={loading}
          link={link}
          onClick={onChange}
          activeClassName={activeClassName}
          disabledClassName={disabledClassName}
        />
      )}

      {/* ... */}
      {startPage > 2 && <PaginationEllipsis key="start-ellipsis" />}

      {/* 中间页 */}
      {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map((page) => (
        <PaginationItem
          className={itemClassName}
          key={page}
          type={type}
          page={page}
          active={Number(page) === currentPage}
          disabled={loading}
          link={link}
          onClick={onChange || function () {}}
          activeClassName={activeClassName}
          disabledClassName={disabledClassName}
        />
      ))}

      {/* ... */}
      {endPage < pageCount - 1 && <PaginationEllipsis key="end-ellipsis" />}

      {/* 最后一页 */}
      <PaginationItem
        className={itemClassName}
        type={type}
        page={pageCount}
        active={pageCount === currentPage}
        disabled={loading}
        link={link}
        onClick={onChange || function () {}}
        activeClassName={activeClassName}
        disabledClassName={disabledClassName}
      />

      {/* 下一页 */}
      {showNext && (
        <PaginationItem
          className={nextClassName || itemClassName}
          key={currentPage + 1}
          type={type}
          page={currentPage + 1}
          disabled={loading || currentPage >= pageCount}
          link={link}
          onClick={onChange || function () {}}
          disabledClassName={disabledClassName}
        >
          下一页
        </PaginationItem>
      )}
    </ul>
  );
}
