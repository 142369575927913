import { socialList } from '@/components/Footer/data';

import SocialLink from './SocialLink';

const Line = ({ data }: { data: IFooterLink[] }) => {
  return (
    <div className="flex flex-row gap-[24px] mb-[9px]">
      {data.map((item, index) => (
        <SocialLink key={index} {...item} />
      ))}
    </div>
  );
};

export default function FooterSocial({ className }: { className?: string }) {
  return (
    <div className={`w-auto text-[rgba(255,255,255,0.6)] ${className}`}>
      <h3 className="text-[12px] leading-[17px]">雀乐社交账号</h3>
      <ul className="mt-[24px]">
        {socialList.map((line, index) => (
          <Line key={index} data={line} />
        ))}
      </ul>
    </div>
  );
}
