'use client';

import { useState } from 'react';

import { Button } from '@/components';

interface Props {
  onSubmit: (value: string) => Promise<boolean>;
}

export default function CommentItem({ onSubmit }: Props) {
  const [value, setValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setLoading(true);
    const res = await onSubmit(value);
    if (res) {
      setValue('');
    }
    setLoading(false);
  };

  return (
    <div>
      <textarea
        value={value}
        cols={30}
        rows={10}
        disabled={loading}
        placeholder="发布评论"
        className="w-[712px] h-[89px] p-[15px] rounded-[3px] placeholder-[rgba(0,0,0,0.4)] bg-[#F2F3F7] text-[15px] leading-[21px] resize-none"
        onChange={(e) => setValue(e.target.value)}
      />
      <Button
        type="primary"
        disabled={loading || !value.trim()}
        className="w-[80px] h-[35px] my-[15px] rounded-[120px] text-[13px] leading-[18.2px]"
        onClick={handleSubmit}
      >
        确定
      </Button>
    </div>
  );
}
