const Input = ({
  type,
  value,
  placeholder,
  maxLength = undefined,
  className = '',
  onChange,
}: {
  type: string;
  value: string | number;
  width?: string;
  height?: string;
  placeholder?: string;
  maxLength?: number;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <input
      className={`w-full h-[56px] rounded-[100px] px-[14px] bg-[#F2F3F7] leading-[20px] ${className}`}
      type={type}
      value={value}
      placeholder={placeholder}
      maxLength={maxLength || undefined}
      onChange={onChange}
    />
  );
};

export default Input;
