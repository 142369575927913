import ButtonDelete from './ButtonDelete';

import { ThumbButton } from '@/components';

interface Props {
  commentCount: number;
  newCommentCount: number;
  thumbupCount: number;
  showAll: boolean;
  haveThumbup: boolean;
  showDelete: boolean;
  className?: string;
  onShowInput: () => void;
  onShowAll: () => void;
  onThumbup: () => Promise<boolean>;
  onDelete: () => Promise<boolean>;
}

export default function ButtonBar({
  commentCount,
  newCommentCount,
  thumbupCount,
  showAll,
  haveThumbup,
  showDelete,
  className,
  onShowAll,
  onShowInput,
  onThumbup,
  onDelete,
}: Props) {
  const handleShowAll = async () => {
    onShowAll();
  };

  return (
    <div className={`flex flex-row items-center gap-18px ${className}`}>
      {/* 回复 */}
      <div
        className="text-14px leading-19.6px text-[rgba(0,0,0,0.4)] hover:text-base cursor-pointer"
        onClick={onShowInput}
      >
        回复
      </div>

      {/* 删除 */}
      {showDelete && <ButtonDelete onDelete={onDelete} />}

      {/* 展开回复 */}
      {commentCount > newCommentCount && commentCount > 1 && (
        <div className="text-brand text-14px leading-19.6px cursor-pointer" onClick={handleShowAll}>
          {showAll ? '收起回复' : `展开${commentCount}条回复`}
        </div>
      )}

      {/* 点赞 */}
      <ThumbButton
        showText
        active={haveThumbup}
        count={thumbupCount}
        iconPosition="right"
        gap={2}
        onClick={onThumbup}
        className="mt-[-2px]"
      />
    </div>
  );
}
