'use client';

import { useState, useEffect } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import { Avatar } from '@/components';
import { useUserStore } from '@/store';
import { logout } from '@/utils';

interface Props {
  className?: string;
}

interface UserData {
  label: string;
  value: number;
  url: string;
}

export default function UserCard({ className }: Props) {
  const router = useRouter();
  const { userInfo } = useUserStore(
    useShallow((state) => ({
      userInfo: state.userInfo,
    })),
  );

  const [userDataList, setUserDataList] = useState<UserData[]>([]);

  const handleGoMyCollect = () => {
    router.push('/my/collect/song');
  };

  const handleLogout = () => {
    logout();
    window.location.replace('/');
  };

  useEffect(() => {
    setUserDataList([
      { label: '关注', value: userInfo.followCount, url: '' },
      { label: '粉丝', value: userInfo.fansCount, url: '' },
      { label: '评论', value: userInfo.commentReplyCount, url: '' },
      { label: '获赞', value: userInfo.thumbUpCount, url: '' },
    ]);
  }, [userInfo]);

  return (
    <div className={`pt-[24px] pb-[18px] rounded-[12px] border-1px border-#e1e1e1 ${className}`}>
      {/* 头像 & 昵称 & 个签 */}
      <div className="flex flex-row items-center px-[27px]">
        <Avatar size={48} src={userInfo.avatar} className="h-[48px] w-[48px] cursor-pointer" alt="我" />

        <div className="ml-[17px]">
          <p className="w-[100px] mt-[1px] text-base text-[15px] leading-[21px] text-overflow">{userInfo.nickName}</p>
          <p className="w-[100px] mt-[3px] text-[rgba(0,0,0,0.4)] text-[12px] leading-[16.8px] text-overflow">
            {userInfo.signature}
          </p>
        </div>
      </div>

      {/* 用户数据 */}
      <ul className="flex flex-row items-center justify-between mt-[12px] px-[19px]">
        {userDataList.map((item, index) => (
          <li key={index} className="flex flex-col justify-between pt-9px pb-12px w-[53px] h-[60px] text-center">
            <div className="text-base text-[15px] leading-[16px]">{item.value}</div>
            <div className="text-[rgba(0,0,0,0.7)] text-[12px] leading-[17px]">{item.label}</div>
          </li>
        ))}
      </ul>

      {/* 按钮 */}
      <ul className="px-[15px] mt-[6px]">
        <li
          className="flex flex-row justify-between items-center w-[238px] h-[49px] px-[15px] rounded-[3px] text-[15px] leading-[21px] hover:bg-[#f2f3f7] cursor-pointer"
          onClick={handleGoMyCollect}
        >
          <span>我的收藏</span>
          <Image width={24} height={24} src="/img/icon/love.svg" alt="我的收藏" />
        </li>
        <li
          className="flex flex-row items-center w-[238px] h-[49px] px-[15px] rounded-[3px] text-[15px] leading-[21px] hover:bg-[#f2f3f7] cursor-pointer"
          onClick={handleLogout}
        >
          <span>退出登录</span>
        </li>
      </ul>
    </div>
  );
}
