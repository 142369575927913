'use client';

import React, { useEffect, useRef } from 'react';

interface Props {
  children: React.ReactNode;
  hasMore: boolean;
  onNext: () => void;
}

const InfiniteScroller = ({ children, hasMore, onNext }: Props) => {
  const loaderRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onNext();
        }
      },
      { threshold: 1 },
    );

    if (loaderRef.current && hasMore) {
      observer.observe(loaderRef.current);
    }

    return () => observer.disconnect();
  }, [onNext]);

  return (
    <div>
      {children}
      <div ref={loaderRef} />
      {hasMore ? (
        <div className="h-[600px] leading-[200px] text-[14px] text-center">{'正在加载……'}</div>
      ) : (
        <div className="h-[160px]" />
      )}
    </div>
  );
};

export default InfiniteScroller;
