import { Avatar } from '@/components';

interface Props extends Comment {
  className?: string;
}

export default function ChildCommentItem({ avatar, nickName, publishTime, location, content, className }: Props) {
  return (
    <div className={`w-[655px] ${className}`}>
      <div className="flex flex-row">
        <Avatar size={20} src={avatar} />
        <div className="ml-[5px]">
          <div className="w-[630px] text-[13px] leading-[20px] text-overflow">
            <span className="text-[rgba(0,0,0,0.4)]">{`${nickName}:  `}</span>
            <span className="text-base text-overflow">{content}</span>
            {/* 归属地 */}
          </div>
          <div className="mt-[4px] text-[rgba(0,0,0,0.4)] text-[10px] leading-[14px]">{`${publishTime} ${location}`}</div>
        </div>
      </div>
    </div>
  );
}
