'use client';

import { useState } from 'react';

import { useShallow } from 'zustand/react/shallow';

import ButtonProvide from './ButtonProvide';
import VolumeBar from './VolumeBar';

import { useAudioStore } from '@/store';
export default function Volume({
  value,
  className,
  onChange,
}: {
  value: number;
  className?: string;
  onChange: (value: number) => void;
}) {
  const { preMuteVolume, setPreMuteVolume } = useAudioStore(
    useShallow((state) => {
      return {
        preMuteVolume: state.preMuteVolume,
        setPreMuteVolume: state.setPreMuteVolume,
      };
    }),
  );

  const [hover, setHover] = useState<boolean>(false);

  const handleMute = () => {
    if (value > 0) {
      // 当前有音量
      setPreMuteVolume(value);
      onChange(0);
    } else {
      // 当前无音量
      onChange(preMuteVolume);
    }
  };

  return (
    <div
      className={`flex flex-col justify-between relative w-22px cursor-pointer ${hover ? 'h-150px' : 'h-22px'} ${className}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {/* 音量条 */}
      <VolumeBar value={value} className={`${!hover && '!hidden'} transition-all`} onChange={onChange} />
      {/* 按钮 */}
      <ButtonProvide className="group" onClick={handleMute}>
        {value >= 50 ? (
          <VolumeFull hover={hover} />
        ) : value === 0 ? (
          <VolumeOff hover={hover} />
        ) : (
          <VolumeLow hover={hover} />
        )}
      </ButtonProvide>
    </div>
  );
}

const VolumeLow = ({ hover }: { hover: boolean }) => (
  <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="transition-all"
      d="M11 4L6 8.57143H2V15.4286H6L11 20V4Z"
      stroke={hover ? 'rgba(0,0,0,0.95)' : 'rgba(0,0,0,0.7)'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="transition-all"
      d="M15.54 8.46C16.4773 9.39764 17.0039 10.6692 17.0039 11.995C17.0039 13.3208 16.4773 14.5924 15.54 15.53"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const VolumeOff = ({ hover }: { hover: boolean }) => (
  <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="transition-all"
      d="M11 4L6 8.57143H2V15.4286H6L11 20V4Z"
      stroke={hover ? 'rgba(0,0,0,0.95)' : 'rgba(0,0,0,0.7)'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="transition-all"
      d="M23 9L17 15"
      stroke={hover ? 'rgba(0,0,0,0.95)' : 'rgba(0,0,0,0.7)'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="transition-all"
      d="M17 9L23 15"
      stroke={hover ? 'rgba(0,0,0,0.95)' : 'rgba(0,0,0,0.7)'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const VolumeFull = ({ hover }: { hover: boolean }) => (
  <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="transition-all"
      d="M11 4L6 8.57143H2V15.4286H6L11 20V4Z"
      stroke={hover ? 'rgba(0,0,0,0.95)' : 'rgba(0,0,0,0.7)'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="transition-all"
      d="M19.07 4.93C20.9447 6.80528 21.9979 9.34836 21.9979 12C21.9979 14.6516 20.9447 17.1947 19.07 19.07M15.54 8.46C16.4774 9.39764 17.0039 10.6692 17.0039 11.995C17.0039 13.3208 16.4774 14.5924 15.54 15.53"
      stroke={hover ? 'rgba(0,0,0,0.95)' : 'rgba(0,0,0,0.7)'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
