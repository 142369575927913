'use client';
import { useEffect, useState } from 'react';

import Image from 'next/image';
import { useShallow } from 'zustand/react/shallow';

import { apiGetSongInfo } from '@/services';
import { useAudioStore } from '@/store';

export default function ArticleMusicCard({ className, id }: { className?: string; id: string }) {
  const {
    playState,
    audioId,
    playListInfo,
    playList,
    playQueue,
    setPlayState,
    setPlayListInfo,
    setPlayQueue,
    setPlayList,
    setAudioId,
  } = useAudioStore(
    useShallow((state) => ({
      playState: state.playState,
      audioId: state.audioId,
      playListInfo: state.playListInfo,
      playList: state.playList,
      playQueue: state.playQueue,
      setPlayState: state.setPlayState,
      setPlayListInfo: state.setPlayListInfo,
      setPlayList: state.setPlayList,
      setPlayQueue: state.setPlayQueue,
      setAudioId: state.setAudioId,
    })),
  );

  const [songInfo, setSongInfo] = useState<SongInfo | null>(null);

  const getSongInfo = async (id: string) => {
    const res = await apiGetSongInfo(id);
    if (res.code === 200) {
      setSongInfo(res.data);
    }
  };

  const handlePlay = (id: string) => {
    if (!id) return;

    // 如果是播放中的歌曲，改变播放状态
    if (id === audioId) {
      setPlayState(!playState);
      return;
    }

    // 如果选择的歌曲在队列中，直接切歌
    const isInQueue = playQueue.some((item) => item.id === id); // 所选的歌在队列中

    if (isInQueue) {
      setAudioId(id);
      return;
    }

    setPlayList([...playList, songInfo as SongInfo]);
    setPlayQueue([...playQueue, songInfo as SongInfo]);
    setAudioId(id);
  };

  useEffect(() => {
    if (id) {
      getSongInfo(id);
    }
  }, [id]);

  return (
    <div
      className={`flex justify-between items-center w-full h-88px py-20px pl-12px pr-30px font-normal rounded-6px border-#E5E5E5 ${className}`}
      style={{
        boxShadow: '0px 0px 11.3px rgba(0, 0, 0, 0.1)',
      }}
    >
      <div className="flex items-center">
        <div className="relative w-48px h-48px rounded-2px overflow-hidden">
          {songInfo?.pic && <Image src={songInfo.pic} alt={songInfo.title} fill unoptimized />}
        </div>

        <div className="flex flex-col ml-15px not-italic">
          <div className="w-fit text-15px leading-21px text-base">{songInfo?.title}</div>
          <div className="w-fit text-12px leading-17px text-base/40">{`${songInfo?.album}/${songInfo?.artist}`}</div>
        </div>
      </div>

      <div
        className="flex justify-center items-center w-45px h-32px bg-brand rounded-30px cursor-pointer"
        onClick={() => handlePlay(id)}
      >
        {playState ? (
          <svg width={45} height={32} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="27" cy="27" r="27" className={`fill-brand group-hover:fill-#B13232 transition-all`} />
            <rect x="20.7" y="18.9" width="4.5" height="16.2" rx="0.736" fill="white" />
            <rect x="28.8" y="18.9" width="4.5" height="16.2" rx="0.736" fill="white" />
          </svg>
        ) : (
          <svg width={45} height={32} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="27" cy="27" r="27" className={`fill-brand group-hover:fill-#B13232 transition-all`} />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.0103 10.2691C21.3409 11.0395 21.3409 12.9605 20.0103 13.7309L7.00207 21.262C5.66874 22.0339 4 21.0718 4 19.5311L4 4.4689C4 2.92823 5.66874 1.96611 7.00207 2.73804L20.0103 10.2691Z"
              fill="white"
              fillOpacity="0.95"
              transform="translate(15, 15)"
            />
          </svg>
        )}
      </div>
    </div>
  );
}
