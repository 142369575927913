import { handleResponse, createFormBody } from '@/utils/request/client/utils';
import { IResponse } from '@/utils/request/type';

import { getAuthorization } from '@/utils';

export const get = async <T>(url: string, revalidate = 20): Promise<IResponse<T>> => {
  const token = await getAuthorization();
  const response = await fetch(url, {
    headers: {
      Authorization: token || '',
      device_type: 'web',
    },
    method: 'GET',
    next: {
      revalidate: revalidate,
    },
  });
  return await handleResponse(response);
};

export const post = async <T>(url: string, data: any, headers?: any, revalidate = 20): Promise<IResponse<T>> => {
  const token = await getAuthorization();
  const response = await fetch(url, {
    headers: {
      Authorization: token || '',
      'Content-Type': headers?.['Content-Type'] || 'application/x-www-form-urlencoded;charset=UTF-8',
      Connection: 'keep-alive',
    },
    method: 'POST',
    body: headers?.['Content-Type'] === 'application/json' ? JSON.stringify(data) : createFormBody(data),
    next: {
      revalidate: revalidate,
    },
  });

  return await handleResponse(response);
};

export const remove = async <T>(url: string, data: any = {}, revalidate = 20): Promise<IResponse<T>> => {
  const token = await getAuthorization();
  const response = await fetch(url, {
    headers: {
      Authorization: token || '',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Connection: 'keep-alive',
    },
    method: 'DELETE',
    body: data && createFormBody(data),
    next: {
      revalidate: revalidate,
    },
  });

  return await handleResponse(response);
};

export const put = async <T>(url: string, data: any = {}, revalidate = 20): Promise<IResponse<T>> => {
  const token = await getAuthorization();
  const response = await fetch(url, {
    headers: {
      Authorization: token || '',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Connection: 'keep-alive',
    },
    method: 'PUT',
    body: data && createFormBody(data),
    next: {
      revalidate: revalidate,
    },
  });

  return await handleResponse(response);
};

export const uploadFile = async <T>(url: string, file: File): Promise<IResponse<T>> => {
  const token = await getAuthorization();
  const formData = new FormData();
  formData.append('file', file);
  const response = await fetch(url, {
    headers: {
      authorization: token || '',
    },
    method: 'POST',
    body: formData,
  });
  return await handleResponse(response);
};

const clientHttp = {
  get,
  post,
  delete: remove,
  put,
  uploadFile,
};
export default clientHttp;
