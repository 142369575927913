import parse from 'html-react-parser';
import { HTMLReactParserOptions, Element } from 'html-react-parser';
import Image from 'next/image';

import { ArticleMusicCard } from '@/components';

export default function HtmlPaser({ html }: { html: string }) {
  const parseHtml = (htmlString: string) => {
    const options: HTMLReactParserOptions = {
      replace(domNode) {
        if (domNode instanceof Element && domNode.name === 'img') {
          const src = domNode.attribs?.src;
          if (src) {
            return (
              <div className="w-full my-30px rounded-6px overflow-hidden">
                <Image src={src} alt={src} width={0} height={0} sizes="100vw" className="w-full h-auto" unoptimized />
              </div>
            );
          }
        }

        if (domNode instanceof Element && domNode.name === 'audio') {
          const src = domNode.attribs?.src;
          const id = domNode.attribs?.['data-song-id'];
          if (src) {
            return <ArticleMusicCard id={id} className="my-30px" />;
          }
        }
      },
    };

    return parse(htmlString, options);
  };

  return <article className="text-14px leading-28px">{parseHtml(html)}</article>;
}
