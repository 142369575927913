import React, { ReactElement, createContext, useReducer } from 'react';

type State = { showLogin: boolean };
type Action = { type: 'SHOW_LOGIN'; payload: boolean };

const initialState: State = { showLogin: false };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SHOW_LOGIN':
      return { ...state, showLogin: action.payload };
    default:
      return state;
  }
}

export const LoginContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({ state: initialState, dispatch: () => null });

let globalDispatch: React.Dispatch<Action> | null = null;

export function openLogin() {
  globalDispatch?.({ type: 'SHOW_LOGIN', payload: true });
}

export const LoginProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  globalDispatch = dispatch;

  return <LoginContext.Provider value={{ state, dispatch }}>{children}</LoginContext.Provider>;
};
