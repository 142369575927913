'use client';
import { useState, useEffect } from 'react';

import { useToast } from '@/components/ui/use-toast';

import DownLoadItem from './DownLoadItem';

import { QRCodeDialog } from '@/components';
import { apiAppVersionCheck } from '@/services';

export default function FooterPlatform({ className }: { className?: string }) {
  const { toast } = useToast();
  const [ios, setIos] = useState<VersionCheck | null>(null);
  const [android, setAndroid] = useState<VersionCheck | null>(null);
  // const [mac, setMac] = useState<VersionCheck>(null);
  // const [pad, setPad] = useState<VersionCheck>(null);

  const handleToast = () => {
    toast({ description: '敬请期待', duration: 1500 });
  };

  const getData = async () => {
    const [ios, android] = await Promise.all([
      apiAppVersionCheck('ios'),
      apiAppVersionCheck('android'),
      // apiAppVersionCheck('mac'),
      // apiAppVersionCheck('pad'),
    ]);
    if (ios.code === 200) setIos(ios.data);
    if (android.code === 200) setAndroid(android.data);
    // if (mac.code === 200) setMac(mac.data);
    // if (pad.code === 200) setPad(pad.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={`w-auto text-[rgba(255,255,255,0.6)] ${className}`}>
      <h3 className="text-[12px] leading-[17px]">下载雀乐客户端</h3>
      <ul className="flex flex-row mt-[19px] gap-[40px]">
        <QRCodeDialog
          header="IOS版下载"
          subHeader={ios?.version ? `最新版V${ios.version}` : ''}
          buttonText="去App Store下载"
          img="/img/download/qrcode_qq_store.svg"
          url={ios?.url ?? ''}
        >
          <DownLoadItem platform="ios" />
        </QRCodeDialog>

        <QRCodeDialog
          header="安卓版下载"
          subHeader={android?.version ? `最新版V${android.version}` : ''}
          buttonText="下载安装包至本地"
          img="/img/download/qrcode_qq_store.svg"
          url={android?.url ?? ''}
        >
          <DownLoadItem platform="android" />
        </QRCodeDialog>

        <div onClick={handleToast}>
          <DownLoadItem platform="mac" />
        </div>

        <div onClick={handleToast}>
          {/* <QRCodeDialog header="Pad版下载" subHeader={`最新版V${pad.version}`}  buttonText="去App Store下载" url={pad.url}> */}
          <DownLoadItem platform="pad" />
          {/* </QRCodeDialog> */}
        </div>
      </ul>
    </div>
  );
}
