'use client';

import { useState } from 'react';

import { Dialog, DialogContent, DialogTrigger, DialogClose } from '@/components/ui/dialog';

export default function ButtonDelete({ onDelete }: { onDelete: () => void }) {
  const [show, setshow] = useState<boolean>(false);

  const handleDelete = () => {
    onDelete();
    setshow(false);
  };

  return (
    <Dialog open={show} onOpenChange={setshow}>
      <DialogTrigger asChild>
        <div className="text-[14px] leading-[19.6px] text-[rgba(0,0,0,0.4)] hover:text-brand cursor-pointer">删除</div>
      </DialogTrigger>
      <DialogContent className="block !w-284px !h-140px !rounded-12px py-24px! px-18px">
        <div className="w-248px mx-auto text-[rgba(0,0,0,0.95)] text-center text-14px leading-24px font-500">
          确定删除评论？
        </div>
        <div className="flex justify-between w-248px mt-24px">
          <DialogClose asChild>
            <button className="w-118px h-44px rounded-999px text-center text-[rgba(0,0,0,0.7)] text-14px leading-44px bg-[rgba(0,0,0,0.05)]">
              取消
            </button>
          </DialogClose>

          <button
            className="w-118px h-44px rounded-999px text-center text-#fff text-14px leading-44px bg-brand"
            onClick={handleDelete}
          >
            确定删除
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
