import * as SliderPrimitive from '@radix-ui/react-slider';

import styles from './index.module.css';

export default function Volumne({
  value,
  className,
  onChange,
}: {
  value: number;
  className?: string;
  onChange: (value: number) => void;
}) {
  const handleChange = (value: number) => {
    onChange(value);
  };

  return (
    <div
      className={`relative flex items-center w-18px h-118px rounded-18px bg-#fff ${styles.VolumeBarContainer} ${className}`}
    >
      <SliderPrimitive.Root
        value={[value]}
        orientation="vertical"
        className={`flex flex-col items-center w-full h-107px ${styles.VolumeBarRoot}`}
        onValueChange={(value: number[]) => handleChange(value[0])}
      >
        <SliderPrimitive.Track className={`${styles.VolumeBarTrack}`}>
          <SliderPrimitive.Range className={`${styles.VolumeBarRange}`} />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb className={`${styles.VolumeBarThumb}`} />
      </SliderPrimitive.Root>
    </div>
  );
}
