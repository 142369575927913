'use client';

import * as React from 'react';

import Autoplay from 'embla-carousel-autoplay';
import Image from 'next/image';
import Link from 'next/link';

import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel';

export default function HomeCarousel({ className, list }: { className: string; list: Carousel[] }) {
  const plugin = React.useRef(Autoplay({ delay: 2000, stopOnInteraction: false, stopOnMouseEnter: true }));

  return (
    <Carousel
      plugins={[plugin.current]}
      className={className}
      onMouseEnter={plugin.current.stop}
      onMouseLeave={plugin.current.reset}
      opts={{
        align: 'start',
        loop: true,
      }}
    >
      <CarouselContent>
        {list.map((item, index) => (
          <CarouselItem key={index}>
            <Link className="group" href={item.outerLink} target="_blank">
              <Image
                className="group-hover:transform-scale-103 transition-transform-300"
                width={340}
                height={120}
                unoptimized
                src={item.imgPath}
                alt=""
              />
            </Link>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  );
}
