import { v4 as uuidv4 } from 'uuid';

export const createDeviceId = () => {
  const uuid: string = uuidv4();
  localStorage.setItem('deviceId', uuid);
  return uuid;
};

export const getDeviceId = () => {
  const localDeviceId = localStorage.getItem('deviceId');
  if (localDeviceId) {
    return localDeviceId;
  } else {
    // localStorge 中没有 deviceId，则创建一个
    const deviceId = createDeviceId();
    return deviceId;
  }
};
