'use client';

import React, { useState, useEffect } from 'react';

import QRCode from 'qrcode';

const QRCodeComponent = ({ text, className }: { text: string; className?: string }) => {
  const [src, setSrc] = useState('');

  useEffect(() => {
    if (!text) return;
    QRCode.toDataURL(text)
      .then((url) => {
        setSrc(url);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [text]);

  return <img className={className} src={src} alt="QR Code" />;
};

export default QRCodeComponent;
