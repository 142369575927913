'use client';

import React from 'react';

import * as Slider from '@radix-ui/react-slider';

import styles from './index.module.css';

export default function ProgressBar({
  currentTime,
  duration,
  className,
  onChange,
  onCommit,
  onChangeDraging,
}: {
  currentTime: number;
  duration: number;
  className?: string;
  onChange: (value: number) => void;
  onCommit: (value: number) => void;
  onChangeDraging: (value: boolean) => void;
}) {
  const handleChange = (v: number) => {
    onChange(v);
  };

  // 光标按下
  const handlePointerDown = () => {
    onChangeDraging(true);
  };

  // 光标抬起
  const handlePointerUp = () => {
    onChangeDraging(false);
    onCommit(currentTime);
  };

  return (
    <Slider.Root
      value={[currentTime]}
      max={duration}
      step={1}
      className={`${styles.ProgressBarRoot} ${className}`}
      onValueChange={(value: number[]) => handleChange(value[0])}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
    >
      <Slider.Track className={`${styles.ProgressBarTrack}`}>
        <Slider.Range className={`${styles.ProgressBarRange}`} />
      </Slider.Track>
      <Slider.Thumb className={`${styles.ProgressBarThumb}`} />
    </Slider.Root>
  );
}
