import ButtonProvide from '../AudioPlayer/widget/ButtonProvide';

export default function PlayButton({
  playState,
  size,
  className,
  onClick,
}: {
  playState: boolean;
  size: number;
  className?: string;
  onClick: () => void;
}) {
  return (
    <ButtonProvide className={`group ${className}`} onClick={onClick}>
      {playState ? (
        <svg width={size} height={size} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="27" cy="27" r="27" className={`fill-brand group-hover:fill-#B13232 transition-all`} />
          <rect x="20.7" y="18.9" width="4.5" height="16.2" rx="0.736" fill="white" />
          <rect x="28.8" y="18.9" width="4.5" height="16.2" rx="0.736" fill="white" />
        </svg>
      ) : (
        <svg width={size} height={size} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="27" cy="27" r="27" className={`fill-brand group-hover:fill-#B13232 transition-all`} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0103 10.2691C21.3409 11.0395 21.3409 12.9605 20.0103 13.7309L7.00207 21.262C5.66874 22.0339 4 21.0718 4 19.5311L4 4.4689C4 2.92823 5.66874 1.96611 7.00207 2.73804L20.0103 10.2691Z"
            fill="white"
            fillOpacity="0.95"
            transform="translate(15, 15)"
          />
        </svg>
      )}
    </ButtonProvide>
  );
}
