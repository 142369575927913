import clientHttp from '@/utils/request/client';

interface CommentRequest {
  journalId: string;
  page: number;
  size: number;
}

export const apiGetCommentHot = async ({ journalId, page, size }: CommentRequest) => {
  const result: FetchResponse<Paging<Comment>> = await clientHttp.get(
    `/queyueapi/comment/comment/hot/${journalId}/${page}/${size}`,
  );
  return result;
};

export const apiGetCommentNew = async ({ journalId, page, size }: CommentRequest) => {
  const result: FetchResponse<Paging<Comment>> = await clientHttp.get(
    `/queyueapi/comment/comment/new/${journalId}/${page}/${size}`,
  );
  return result;
};

/**
 * @description 根据期刊ID获取分页评论列表（热门）,按点赞数倒序
 * 根据期刊ID获取根节点评论分页列表，
 * 数据库中保存的有期刊号的都是根节点，
 * 期刊号为空的代表有父评论且parentID一定有值，
 * 拿到当然根节点评论列表，每个根评论有 commentCount字段，
 * 页面可以显示有 280个回复，然后根据 @GetMapping("/comment/{parentId}/{page}/{size}") 接口，
 * 分页拿到该评论下的子评论列表，页面在请求添加评论的时候，当给评论添加子评论的时候，父评论parentID有值，就无法添加子评论并且不显示回复2字，控制到两级
 * @type 类型 热门: hot 最新: new
 * @journalId 期刊id
 * @page 分页: 页码，以1开始
 * @size 分页: 每页数量
 */
export const apiGetComment = async ({ type, journalId, page, size }: CommentRequest & { type: 'hot' | 'new' }) => {
  const fn = {
    hot: apiGetCommentHot,
    new: apiGetCommentNew,
  };
  const res: FetchResponse<Paging<Comment>> = await fn[type]({ journalId, page, size });
  return res;
};

/**
 * @description 获取评论下的子评论列表
 * @
 */
export const apiGetChildComment = async ({
  parentId,
  page,
  size,
}: {
  parentId: string;
  page: number;
  size: number;
}) => {
  const result: FetchResponse<Paging<Comment>> = await clientHttp.get(
    `/queyueapi/comment/comment/comment/${parentId}/${page}/${size}`,
  );
  return result;
};

/**
 * @description 点赞/取消点赞评论
 */
export const apiCommentThumbup = async (commentId: string) => {
  const result: FetchResponse<string> = await clientHttp.put(`/queyueapi/comment/comment/thumbup/${commentId}`);
  return result;
};

/**
 * @description 添加评论
 * @content 评论内容
 * @journalId 期刊id
 * @parentId 父评论id
 */
export const apiCommentSave = async (params: {
  content: string;
  journalId: string;
  parentId: string;
  type: Serv.Comment.Type;
}) => {
  const result: FetchResponse<Comment> = await clientHttp.post(`/queyueapi/comment/comment`, params, {
    'Content-Type': 'application/json',
  });
  return result;
};

/**
 * @description 删除评论
 */
export const apiCommentDelete = async (commentId: string) => {
  const result: FetchResponse<string> = await clientHttp.delete(`/queyueapi/comment/comment/${commentId}`);
  return result;
};
