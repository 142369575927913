export default function Tab({
  className,
  value,
  changeTab,
}: {
  className?: string;
  value: string;
  changeTab: (value: string) => void;
}) {
  return (
    <div className={`flex justify-center items-center w-full ${className}`}>
      <div
        className={`text-17px leading-24px font-400 cursor-pointer text-#000/70 ${value === 'qrCode' && 'text-#000/95 font-500'}`}
        onClick={() => changeTab('qrCode')}
      >
        扫码登录
      </div>
      <div className="h-14.5px mx-10px border-1px border-solid border-#000/40 " />
      <div
        className={`text-17px leading-24px font-400 cursor-pointer text-#000/70 ${value === 'phone' && 'text-#000/95 font-500'}`}
        onClick={() => changeTab('phone')}
      >
        手机登录
      </div>
    </div>
  );
}
