import React, { ReactElement, createContext, useReducer } from 'react';

interface IState {
  show: boolean;
  content: string;
  onClick?: (...arg: any) => any;
}
type Action = { type: 'SHOW_DIALOG'; payload: boolean };

const initialState: IState = { show: false, content: '', onClick: () => {} };

function reducer(state: IState, action: Action): IState {
  switch (action.type) {
    case 'SHOW_DIALOG':
      return { ...state, show: action.payload };
    default:
      return state;
  }
}

export const DialogContext = createContext<{
  state: IState;
  dispatch: React.Dispatch<Action>;
}>({ state: initialState, dispatch: () => null });

let globalDispatch: React.Dispatch<Action> | null = null;

export function openLogin() {
  globalDispatch?.({ type: 'SHOW_DIALOG', payload: true });
}

export const DialogProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  globalDispatch = dispatch;

  return <DialogContext.Provider value={{ state, dispatch }}>{children}</DialogContext.Provider>;
};
