interface IProps {
  className?: string;
  onInput: (value: string) => void;
  onFocus: () => void;
}

import { useEffect, useState } from 'react';

import { usePathname } from 'next/navigation';

import IconSearch from './widget/IconSearch';

export default function SearchInput({ className, onInput, onFocus }: IProps) {
  const pathname = usePathname();

  const [value, setValue] = useState<string>('');
  const [active, setActive] = useState<boolean>(false);

  const handleInput = (v: string) => {
    setValue(v);
    onInput(v);
  };

  const handleFocus = () => {
    setActive(true);
    onFocus();
  };

  useEffect(() => {
    // 切换页面时，清空搜索框
    setActive(false);
    handleInput('');
  }, [pathname]);

  return (
    <div className={`relative w-208px h-38px ${className}`}>
      <input
        value={value}
        className={`w-full h-full px-45px border-0.5px text-13px leading-38px text-#000/95 placeholder-#000/70 bg-#f2f3f7 rounded-47px ${active || value ? `border-#000/95 bg-transparent` : 'border-#e1e1e1'}`}
        placeholder="输入期刊/歌曲名"
        onInput={(e: any) => handleInput(e.target.value)}
        onFocus={handleFocus}
        onBlur={() => setActive(false)}
        style={{ boxShadow: (active || value) && '0px 6px 34px 0px rgba(0, 0, 0, 0.1)' }}
      />

      <IconSearch className="absolute top-8px left-16px" />
    </div>
  );
}
