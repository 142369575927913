import CardPlayButton from '@/components/Journal/widget/CardPlayButton';

import styles from '../index.module.css';

import { JournalCard } from '@/components';

export default function CoverCard({ title, img, no }: { title: string; img: string; no: string }) {
  return (
    <JournalCard image={img} journalNo={no} scale className="cursor-pointer">
      <div className={`absolute bottom-0 w-full h-[126px] ${styles.title_background}`}>
        <p className="absolute bottom-[23px] left-[23px] text-[#fff] text-[20px] leading-[33.6px] font-500">{title}</p>
      </div>
      <CardPlayButton
        className="absolute bottom-[24px] right-[24px] opacity-0 hover:transform-scale-103 group-hover:opacity-100 hover:transition-transform-150 transition-opacity-500 "
        no={no}
      />
    </JournalCard>
  );
}
