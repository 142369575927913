export const agreementList = [
  {
    name: '知识产权说明',
    url: `${process.env.NEXT_PUBLIC_MOBILE_HOST}/agreement/intellectualPropertyDescription.html`,
  },
  {
    name: '注册协议',
    url: `${process.env.NEXT_PUBLIC_MOBILE_HOST}/agreement/registrationAgreement.html`,
  },
  {
    name: '隐私政策',
    url: `${process.env.NEXT_PUBLIC_MOBILE_HOST}/agreement/privacyPolicy.html`,
  },
  {
    name: '投诉指引',
    url: `${process.env.NEXT_PUBLIC_MOBILE_HOST}/agreement/complaintGuidelines.html`,
  },
  {
    name: '自审制度',
    url: `${process.env.NEXT_PUBLIC_MOBILE_HOST}/agreement/selfCensorship.html`,
  },
];

export const platformList = [
  {
    name: '音乐人入驻',
    url: '#',
  },
  {
    name: '音乐商城',
    url: '#',
  },
];

export const socialList: IFooterLink[][] = [
  [
    {
      name: '雀乐微博',
      url: 'https://weibo.com/u/1886232237',
    },
    {
      name: '雀乐公众号',
      url: 'https://weixin.qq.com/r/thLfx-3EHaBirbmk90ek',
      dialog: {
        header: '雀乐公众号',
        subHeader: '期待关注',
      },
    },
    {
      name: '邮箱',
      url: 'mailto:rock@indie.cn',
    },
    {
      name: '豆瓣',
      url: 'https://www.douban.com/group/luoo',
    },
  ],
  [
    // {
    //   name: '雀乐微信群',
    //   url: '#',
    // },
    // {
    //   name: '关于雀乐',
    //   url: '/about',
    //   target: '_self',
    // },
    {
      name: '抖音',
      url: 'https://www.douyin.com/user/MS4wLjABAAAAc7W6Pt4PbkLp7IdWD6IHUshkBjGwerHZSHn-U5p6JT8',
    },
    {
      name: '小红书',
      url: 'https://www.xiaohongshu.com/user/profile/5fa6cc00000000000101e174',
    },
    {
      name: 'bilibili',
      url: 'https://space.bilibili.com/21386343',
    },
  ],
];
