'use client';

import React, { useState, useMemo, useEffect } from 'react';

import { debounce } from 'lodash';
import { useRouter } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import { useToast } from '@/components/ui/use-toast';

import { Button } from '@/components';
import { useLogin, useLoginRedirect } from '@/hooks';
import { apiSendSMS } from '@/services';
import { useUserStore } from '@/store';
import { createDeviceId, getDeviceId } from '@/utils';

export default function LoginForm({ className }: { className?: string }) {
  const redirect = useLoginRedirect();
  const router = useRouter();
  const { toast } = useToast();
  const { SmsLogin } = useLogin();

  const { setShowLogin, getUserInfo } = useUserStore(
    useShallow((state) => ({
      setShowLogin: state.setShowLogin,
      getUserInfo: state.getUserInfo,
    })),
  );

  const [phone, setPhone] = useState<string>(''); // 手机号
  const [authCode, setAuthCode] = useState<string>(''); // 验证码
  const [errorText, setErrorText] = useState<string>(''); // 验证码
  const [buttonText, setButtonText] = useState<string>('获取验证码'); // 获取验证码 按钮文案
  const [time, setTime] = useState<number>(0); // 倒计时
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const activeSMS = useMemo(() => phone.length !== 11 || !!time, [phone, time]);
  const disLogin = useMemo(() => !(phone.length === 11 && authCode.length === 6), [phone, authCode]); // 是否激活发送短信按钮

  const handleSendSMS = async () => {
    setBtnLoading(true);

    // 生成 deviceId
    const deviceId = createDeviceId();

    // 设置倒计时
    const gapTime: number = 59;
    setTime(gapTime);
    const timer = setTimeout(() => {
      setBtnLoading(false);
    }, gapTime * 1000);

    const result = await apiSendSMS({ mobile: phone, deviceId });

    if (result.code !== 200) {
      // 显示错误信息
      setErrorText(result.message);

      // 取消倒计时
      setTime(0);
      clearTimeout(timer);

      // 按钮状态
      setBtnLoading(false);
      return;
    }

    setButtonText('重新获取');
  };

  // 登录
  const handleLogin = async () => {
    if (disLogin) return;
    setBtnLoading(true);

    const deviceId = getDeviceId();
    const result = await SmsLogin({ phone, authCode: authCode, deviceId });
    if (result.code === 200) {
      setShowLogin(false);
      await getUserInfo();
      toast({
        description: '登录成功',
        duration: 1500,
        type: 'background',
      });
      if (redirect) router.replace(redirect);
    } else {
      setErrorText(result.message);
      setBtnLoading(false);
    }
  };

  // 输入框只允许输入数字
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fn: (text: string) => void) => {
    if (errorText) setErrorText('');
    const inputValue = e.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(inputValue) || inputValue === '') fn(e.target.value);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (time > 0) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [time]);

  useEffect(() => {
    const handleListenKeyboard = (e: KeyboardEvent) => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        handleLogin();
      }
    };

    document.addEventListener('keydown', handleListenKeyboard);
    return () => {
      document.removeEventListener('keydown', handleListenKeyboard);
    };
  }, []);

  return (
    <div className={className}>
      <div className={`w-full flex flex-col`}>
        <input
          className="w-full h-50px rounded-100px !placeholder-[rgba(0,0,0,0.4)] !placeholder-text-14px px-24px text-[rgba(0,0,0,0.95)] bg-#fff leading-20px border-1px border-#fff focus:border-#000/70 transition-all-100"
          type="text"
          value={phone}
          maxLength={11}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, setPhone)}
          placeholder="输入手机号"
        />

        <div className="relative w-full mt-16px">
          <input
            className="w-full h-50px rounded-100px pl-24px pr-[calc(24px_+_60px)] !placeholder-[rgba(0,0,0,0.4)] !placeholder-text-14px text-[rgba(0,0,0,0.95)] bg-#fff leading-20px border-1px border-1px border-#fff focus:border-#000/70 transition-all-100"
            type="text"
            value={authCode}
            maxLength={6}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, setAuthCode)}
            placeholder="输入验证码"
          />

          <Button
            type="white"
            className={`absolute top-15px right-24px w-fit h-fit rounded-100px font-size-14px text-right leading-20px`}
            disabled={btnLoading || activeSMS}
            onClick={debounce(handleSendSMS, 300)}
          >
            {time ? (
              <span className="text-[rgba(0,0,0,0.4)] cursor-default">{`${time}S`}</span>
            ) : (
              <span className="text-brand cursor-pointer">{buttonText}</span>
            )}
          </Button>
        </div>
      </div>

      <Button
        type="primary"
        className="w-full h-50px mt-30px rounded-100px font-size-14px leading-20px"
        disabled={disLogin}
        onClick={debounce(handleLogin, 300)}
      >
        确定登录
      </Button>

      <p className="mt-16px text-center text-brand text-13px leading-18px">{errorText}</p>
    </div>
  );
}
