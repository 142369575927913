import clientHttp from '@/utils/request/client';

/**
 * @description 查询版本号
 */

export const apiAppVersionCheck: (platform: AppPlatform) => Promise<FetchResponse<VersionCheck>> = async (platform) => {
  const params = {
    platform,
    deviceId: 'web',
    appVersion: '1.0.0',
  };
  const result: FetchResponse<VersionCheck> = await clientHttp.post(`/queyueapi/user/update/checkVersion`, params);
  return result;
};
