import JournalItem from './JournalItem';

const JournalList = async ({ dataList }: { dataList: JournalInfo[] }) => {
  return (
    <div className="flex flex-col gap-[60px] mt-[17px]">
      {dataList?.length && dataList.map((item: JournalInfo) => <JournalItem key={item.id} {...item} />)}
    </div>
  );
};

export default JournalList;
