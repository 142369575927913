import React from 'react';

interface ButtonProps {
  type?: 'default' | 'primary' | 'black' | 'white';
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
  [key: string]: any;
}

const Button: React.FC<ButtonProps> = ({
  type = 'default',
  disabled = false,
  className = '',
  children,
  ...restPropps
}) => {
  const buttonColorList = {
    default: { default: 'bg-blue text-[#000]', disabled: 'opacity-50' },
    primary: { default: 'bg-brand text-[#fff]', disabled: 'opacity-50' },
    white: { default: 'bg-[#fff] text-brand', disabled: 'text-[rgba(0,0,0,0.4)]' },
    black: { default: 'bg-[#000] text-[#fff]', disabled: 'opacity-10' },
  };

  return (
    <button
      type="button"
      className={`${buttonColorList[type].default} ${disabled && buttonColorList[type].disabled} ${className}
      `}
      disabled={disabled}
      {...restPropps}
    >
      {children}
    </button>
  );
};

export default Button;
