/**
 * @description 歌单列表
 * 使用了虚拟列表的歌单
 */
'use client';

import React, { useEffect, useRef } from 'react';
import { FixedSizeList } from 'react-window';

import styles from './virtualSongList.module.css';

import { SongCard } from '@/components';

interface Props {
  /** 正在播放的音频id */
  activeId: string;
  /** 列表类型 vol: 期刊; collectSingle: 收藏单曲; playerCard: 播放器内; */
  listInfo: { type: 'vol' | 'collectSingle' | 'playerCard'; id: string | null };
  /** 歌曲列表 */
  songList: SongInfo[];
  /** 播放状态 */
  playState: boolean;
  /** 列表高度 */
  height: number;
  /** 收藏按钮的显示逻辑 always: 总是显示 playing: 播放时显示 */
  collectType?: 'always' | 'playing';
  className?: string;
  onPlay?: (id: string) => void;
}

export default function VirtualSongList({
  playState,
  height,
  activeId,
  songList,
  className,
  collectType = 'always',
  onPlay,
}: Props) {
  const listRef = useRef<FixedSizeList>(null);

  // 点击某首歌曲播放
  const handlePlayList = (id: string) => {
    if (id && onPlay) onPlay(id);
  };

  // 自动滚动
  useEffect(() => {
    const activeInex = songList.findIndex((item) => item.id === activeId);
    if (listRef.current) listRef.current.scrollToItem(activeInex, 'smart');
  }, [activeId, songList]);

  const Item = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    const item = songList[index];
    return (
      <div key={item?.id} style={style} className="pr-14px">
        <SongCard
          {...item}
          playState={playState}
          showEq={item?.id === activeId}
          onPlay={handlePlayList}
          showCollect={collectType === 'always' || (collectType === 'playing' && item.id === activeId)}
        />
      </div>
    );
  };

  return (
    <FixedSizeList
      ref={listRef}
      className={`${styles.SongList} ${className}`}
      width={712}
      height={height}
      itemCount={songList.length}
      itemSize={78}
    >
      {Item}
    </FixedSizeList>
  );
}
