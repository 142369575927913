import { socialList } from '../data';

import SocialLink from './SocialLink';

export default function FooterSocial({ className }: { className?: string }) {
  return (
    <ul className={`flex flex-row gap-24px w-fit mx-auto ${className}`}>
      {socialList.flat().map((item, index) => (
        <li key={index}>
          <SocialLink key={index} {...item} />
        </li>
      ))}
    </ul>
  );
}
