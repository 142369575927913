'use client';

import { useShallow } from 'zustand/react/shallow';

import { HoverCard, HoverCardPortal, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';

import UserCard from './UserCard';

import { Avatar } from '@/components';
import { useUserStore } from '@/store';

export default function HeaderAvatar({ className }: { className?: string }) {
  const { userInfo } = useUserStore(
    useShallow((state) => ({
      userInfo: state.userInfo,
    })),
  );

  return (
    <div className={`${className}`}>
      <HoverCard openDelay={300}>
        <HoverCardTrigger>
          <Avatar size={36} src={userInfo.avatar} className="h-[36px] w-[36px] cursor-pointer" alt="我" />
        </HoverCardTrigger>

        <HoverCardPortal>
          <HoverCardContent
            sideOffset={12}
            align="end"
            className={`w-fit h-fit p-0 rounded-[12px] bg-[#fff] border-none overflow-hidden`}
          >
            <UserCard />
          </HoverCardContent>
        </HoverCardPortal>
      </HoverCard>
    </div>
  );
}
