'use client';

import { useContext } from 'react';

import { Dialog, DialogContent, DialogClose } from '@/components/ui/dialog';

import { DialogContext } from './dialogContext';

export default function CommonDialog() {
  const { state } = useContext(DialogContext);

  return (
    <Dialog open={state.show}>
      <DialogContent className="block !w-284px !h-140px !rounded-12px py-24px px-18px">
        <div className="w-248px mx-auto text-[rgba(0,0,0,0.95)] text-center text-14px leading-24px font-500">
          确定删除评论？
        </div>
        <div className="flex justify-between w-248px mt-24px">
          <DialogClose asChild>
            <button
              className="w-118px h-44px rounded-999px text-center text-[rgba(0,0,0,0.7)] text-14px leading-44px bg-[rgba(0,0,0,0.05)]"
              onClick={state.onClick}
            >
              {state.content}
            </button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
}
