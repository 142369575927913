'use client';

export default function PlayAll({ onClick, className }: { onClick?: () => void; className?: string }) {
  return (
    <button
      className={`flex items-center w-fit h-[26px] text-base cursor-pointer group ${className}`}
      onClick={onClick}
    >
      <svg width="36" height="26" viewBox="0 0 36 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="26" rx="13" fill="#C43737" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3401 11.8461C24.2272 12.3596 24.2272 13.6403 23.3401 14.1539L14.668 19.1746C13.7791 19.6892 12.6666 19.0478 12.6666 18.0207L12.6666 7.97922C12.6666 6.95211 13.7791 6.3107 14.668 6.82532L23.3401 11.8461Z"
          fill="white"
        />
      </svg>

      <div className="ml-9px text-[15px] leading-[26px] group-hover:text-brand">播放全部</div>
    </button>
  );
}
