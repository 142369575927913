import { useState, useEffect, useRef } from 'react';

import { useRouter } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import Lrc from './components/Lrc';
import PlayerCardMeta from './components/PlayerCardMeta';

import { Header, VirtualSongList } from '@/components';
import { useAudioStore } from '@/store';

interface Prop {
  show: boolean;
  className?: string;
}

export default function PlayerCard({ show, className }: Prop) {
  const router = useRouter();
  const {
    audioId,
    playListInfo,
    playQueue,
    playState,
    setPlayState,
    setAudioId,
    setShowCard,
    setPlayList,
    setPlayQueue,
  } = useAudioStore(
    useShallow((state) => {
      return {
        playState: state.playState,
        audioId: state.audioId,
        playListInfo: state.playListInfo,
        playQueue: state.playQueue,
        setPlayState: state.setPlayState,
        setAudioId: state.setAudioId,
        setShowCard: state.setShowCard,
        setPlayList: state.setPlayList,
        setPlayQueue: state.setPlayQueue,
      };
    }),
  );
  const rightRef = useRef<HTMLDivElement>(null);

  const [lrcData, setLrcData] = useState<string>('');
  const [playListHeight, setPlayListHeight] = useState<number>(100);

  const miniClass = 'translate-y-100vh'; // 缩小时的样式
  const largeClass = 'translate-y-0'; // 放大后的样式

  const audioInfo = playQueue.find((item) => item.id === audioId);

  // 获取歌词
  const getLrc = async (lrcUrl: string) => {
    if (!lrcUrl) {
      setLrcData('');
      return;
    }

    fetch(lrcUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text(); // 假设文件是文本格式
      })
      .then((data) => {
        const lrc = data || '';
        setLrcData(lrc);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  };

  // 清空列表
  const handleClearPlayQueue = () => {
    if (audioInfo) {
      setPlayQueue([audioInfo]);
      if (playListInfo.type !== 'fm') setPlayList([audioInfo]);
    }
  };

  // 跳转到期刊详情页面
  const handleGoVol = (no: string) => {
    router.push(`/vol/${no}`);
    setShowCard(false);
  };

  // 播放歌曲
  const handlePlaySongList = (id: string) => {
    // 如果是播放中的歌曲，改变播放状态
    if (id === audioId) {
      setPlayState(!playState);
      return;
    } else {
      setAudioId(id);
      return;
    }
  };

  useEffect(() => {
    getLrc(audioInfo?.lrc ?? '');
  }, [audioInfo]);

  useEffect(() => {
    const rightHeight = rightRef.current?.offsetHeight;
    if (!rightHeight) return;

    const freeHeight = rightHeight - 24 - 15; // 空区域高度
    const listHeight = freeHeight - (freeHeight % 78); // 减掉冗余高度
    setPlayListHeight(listHeight);

    // 隐藏滚动条
    const htmlDom = document.querySelector('html');
    if (htmlDom) htmlDom.style.overflowY = show ? 'hidden' : 'auto';
  }, [show]);

  return (
    <div
      className={`w-100vw h-100vh pb-150px bg-[#fff] transition-all duration-300 z-9 overscroll-y-contain ${show ? largeClass : miniClass} ${className}`}
    >
      <Header type="client" />

      <div className="flex justify-between w-1200px h-[calc(100vh_-_80px_-_88px)] pb-60px mx-auto ">
        <div className="mt-68px">
          {/* 单曲元信息 */}
          {!!audioInfo?.id && (
            <PlayerCardMeta playState={playState} audioInfo={audioInfo} onRouterPush={(volId) => handleGoVol(volId)} />
          )}

          <Lrc lrcData={lrcData} className="mt-[30px] w-[340px] h-[calc(100vh_-_540px_-_60px_-_88px)]" />
        </div>

        {/* 播放列表 */}
        <div className="flex flex-col items-start w-728px h-auto mt-49px" ref={rightRef}>
          <button onClick={handleClearPlayQueue} className="h-24px text-17px leading-24px text-#000/95">
            清空列表
          </button>

          <div className="w-full mt-15px ml-[-18px] overflow-hidden">
            {show && (
              <VirtualSongList
                playState={playState}
                activeId={audioId}
                height={playListHeight}
                songList={playQueue}
                listInfo={{ type: 'playerCard', id: null }}
                onPlay={(id) => handlePlaySongList(id)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
