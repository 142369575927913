import { ReactElement } from 'react';

import ButtonProvide from './ButtonProvide';

export default function Order({
  order,
  className,
  onClick,
}: {
  order: PlayOrder;
  className?: string;
  onClick: () => void;
}) {
  const orderList: Array<{ value: string; label: string; icon: ReactElement }> = [
    { value: 'list_loop', label: '列表循环', icon: <ListLoop /> },
    { value: 'random', label: '随机播放', icon: <Random /> },
    { value: 'single_loop', label: '单曲循环', icon: <SingleLoop /> },
  ];

  // return orderList.map((item) => (
  //   <ButtonProvide className={className} key={item.value} onClick={onClick}>
  //     {item.icon}
  //   </ButtonProvide>
  // ));

  return (
    <ButtonProvide className={`group ${className}`} onClick={onClick}>
      {orderList.find((item) => item.value === order)?.icon ?? null}
    </ButtonProvide>
  );
}

const ListLoop = () => (
  <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="fill-#000/70 group-hover:fill-#000/95 transition-all"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5303 1.46967C18.2374 1.17678 17.7626 1.17678 17.4697 1.46967C17.1768 1.76256 17.1768 2.23744 17.4697 2.53033L19.1893 4.25H8C4.82436 4.25 2.25 6.82436 2.25 10V12C2.25 12.4142 2.58579 12.75 3 12.75C3.41421 12.75 3.75 12.4142 3.75 12V10C3.75 7.65279 5.65279 5.75 8 5.75H19.1893L17.4697 7.46967C17.1768 7.76256 17.1768 8.23744 17.4697 8.53033C17.7626 8.82322 18.2374 8.82322 18.5303 8.53033L21.5303 5.53033C21.6768 5.38388 21.75 5.19194 21.75 5C21.75 4.89831 21.7298 4.80134 21.6931 4.71291C21.6565 4.62445 21.6022 4.54158 21.5303 4.46967L18.5303 1.46967Z"
    />
    <path
      className="fill-#000/70 group-hover:fill-#000/95 transition-all"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.46967 22.5303C5.76256 22.8232 6.23744 22.8232 6.53033 22.5303C6.82322 22.2374 6.82322 21.7626 6.53033 21.4697L4.81066 19.75H16C19.1756 19.75 21.75 17.1756 21.75 14V12C21.75 11.5858 21.4142 11.25 21 11.25C20.5858 11.25 20.25 11.5858 20.25 12V14C20.25 16.3472 18.3472 18.25 16 18.25H4.81066L6.53033 16.5303C6.82322 16.2374 6.82322 15.7626 6.53033 15.4697C6.23744 15.1768 5.76256 15.1768 5.46967 15.4697L2.46967 18.4697C2.32322 18.6161 2.25 18.8081 2.25 19C2.25 19.1017 2.27024 19.1987 2.30691 19.2871C2.34351 19.3755 2.39776 19.4584 2.46967 19.5303L5.46967 22.5303Z"
    />
  </svg>
);

const Random = () => (
  <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="stroke-[rgba(0,0,0,0.7)] group-hover:stroke-#000/95 transition-all"
      d="M15.5 3H20.5V8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke-[rgba(0,0,0,0.7)] group-hover:stroke-#000/95 transition-all"
      d="M3.5 20L20.5 3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke-[rgba(0,0,0,0.7)] group-hover:stroke-#000/95 transition-all"
      d="M20.5 16V21H15.5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke-[rgba(0,0,0,0.7)] group-hover:stroke-#000/95 transition-all"
      d="M14.5 15L20.5 21"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke-[rgba(0,0,0,0.7)] group-hover:stroke-#000/95 transition-all"
      d="M3.5 4L8.5 9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SingleLoop = () => (
  <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="fill-#000/70 group-hover:fill-#000/95 transition-all"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5303 1.46967C18.2374 1.17678 17.7626 1.17678 17.4697 1.46967C17.1768 1.76256 17.1768 2.23744 17.4697 2.53033L19.1893 4.25H8C4.82436 4.25 2.25 6.82436 2.25 10V16C2.25 19.1756 4.82436 21.75 8 21.75H16C19.1757 21.75 21.75 19.1755 21.75 15.9999V14.5C21.75 14.0858 21.4142 13.75 21 13.75C20.5858 13.75 20.25 14.0858 20.25 14.5V15.9999C20.25 18.3471 18.3472 20.25 16 20.25H8C5.65279 20.25 3.75 18.3472 3.75 16V10C3.75 7.65279 5.65279 5.75 8 5.75H19.1893L17.4697 7.46967C17.1768 7.76256 17.1768 8.23744 17.4697 8.53033C17.7626 8.82322 18.2374 8.82322 18.5303 8.53033L21.5303 5.53033C21.6768 5.38388 21.75 5.19194 21.75 5C21.75 4.89831 21.7298 4.80134 21.6931 4.71291C21.6565 4.62445 21.6022 4.54158 21.5303 4.46967L18.5303 1.46967Z"
    />
    <path
      className="fill-#000/70 group-hover:fill-#000/95 transition-all"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6707 9.12895C12.8768 9.26848 13.0002 9.50114 13.0002 9.75V16.25C13.0002 16.6642 12.6644 17 12.2502 17C11.836 17 11.5002 16.6642 11.5002 16.25V10.8578L10.0287 11.4464C9.64415 11.6002 9.20768 11.4131 9.05384 11.0285C8.90001 10.6439 9.08707 10.2075 9.47166 10.0536L11.9717 9.05365C12.2027 8.96122 12.4646 8.98943 12.6707 9.12895Z"
    />
  </svg>
);
