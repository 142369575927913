import Link from 'next/link';

import { QRCodeDialog } from '@/components';

export default function SocialLink({ name, url, target, dialog }: IFooterLink) {
  return !!dialog ? (
    <QRCodeDialog header={dialog.header} subHeader={dialog.subHeader} url={url}>
      <div className="text-[rgba(255,255,255,0.6)] hover:text-[rgba(255,255,255,0.9)] text-[12px] leading-[17px]  transition-colors">
        {name}
      </div>
    </QRCodeDialog>
  ) : (
    <Link
      className="text-[rgba(255,255,255,0.6)] hover:text-[rgba(255,255,255,0.9)] text-[12px] leading-[17px]  transition-colors"
      href={url}
      target={target || '_blank'}
    >
      {name}
    </Link>
  );
}
