'use client';

import Image from 'next/image';

import { Dialog, DialogContent, DialogTrigger, DialogClose } from '@/components/ui/dialog';

import { IconClose } from '@/components';

export default function QRCodeDialog({
  header,
  buttonText,
  url,
  img,
  subHeader,
  children,
}: {
  header: string;
  url: string;
  img?: string;
  children: React.ReactNode;
  subHeader?: string;
  buttonText?: string;
}) {
  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>

      <DialogContent className="w-420px">
        <DialogClose className="absolute top-[24px] right-[24px] w-24px h-24px p-0 border-none cursor-pointer">
          <IconClose />
        </DialogClose>
        {/* 标题 */}
        <p className="text-[#000] text-[24px] leading-[34px] font-bold ">{header}</p>

        {/* 副标题 */}
        <p className="h-18px mt-[2px] text-[#000] text-opacity-70 text-[13px] leading-[18px] ">{subHeader ?? ''}</p>

        {/* 二维码 */}
        <div className="w-[200px] h-[200px] mt-[24px] bg-[#fff] rounded-[6px] overflow-hidden">
          {!!(img || url) && <Image width={200} height={200} src={img || url} alt="" />}
        </div>

        {/* 按钮 */}
        {buttonText ? (
          <button
            className="w-[280px] h-[56px] rounded-[100px] text-[#fff] text-[14px] leading-[20px] bg-brand mt-[38px]"
            onClick={() => window.open(url)}
          >
            {buttonText}
          </button>
        ) : (
          <div className="h-4px" />
        )}
      </DialogContent>
    </Dialog>
  );
}
