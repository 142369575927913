'use client';

import { useState, useEffect, useCallback } from 'react';

import { useToast } from '@/components/ui/use-toast';

import CommentItem from './CommentItem';

import { InfiniteScroller } from '@/components';
import { apiGetComment, apiCommentDelete } from '@/services';

const CommentList = ({
  journalId,
  commentType,
  newCommentList,
  className,
  type,
}: {
  journalId: string;
  commentType: 'hot' | 'new';
  newCommentList: Comment[];
  className?: string;
  type: Serv.Comment.Type;
}) => {
  const { toast } = useToast();

  const [commentList, setCommentList] = useState<Comment[]>([]);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const pageSize = 5;

  // 加载评论
  const getComments = useCallback(async () => {
    if (loading) return;

    setLoading(true);
    const res = await apiGetComment({ type: commentType, journalId, page, size: pageSize });
    if (res.code === 200) {
      const list = res.data.rows;
      if (list.length < pageSize) setHasMore(false); // 如果 length < pageSize 则不再加载
      const finalList = list.filter((item: Comment) => !newCommentList.some((nItem) => item._id === nItem._id)); // 过滤自己新发布的评论
      setPage((prePage) => prePage + 1);
      setCommentList([...commentList, ...finalList]);
    }
    setLoading(false);
  }, [journalId, page, commentType, loading, commentList]);

  // 删除评论
  const handleDelete = async (id: string) => {
    const res = await apiCommentDelete(id);
    if (res.code === 200) {
      const delDom = document.querySelector(`.comment_${id}`);
      if (delDom) delDom.remove();

      toast({
        description: '评论已删除',
        duration: 1500,
      });
    }
    return res.code === 200;
  };

  useEffect(() => {
    setHasMore(true);
    setPage(1);
    setCommentList([]);
  }, [commentType]);

  return (
    <InfiniteScroller onNext={getComments} hasMore={hasMore}>
      <div className={`${className}`}>
        {/* 用户新发表的评论 */}
        {!!newCommentList?.length &&
          newCommentList.map((comment) => (
            <CommentItem
              type={type}
              key={comment._id}
              onDelete={handleDelete}
              className={`py-[15px] comment_${comment._id}`}
              {...comment}
            />
          ))}

        {/* 请求到的评论 */}
        {commentList.map((comment) => (
          <CommentItem
            key={comment._id}
            onDelete={handleDelete}
            className={`py-[15px] comment_${comment._id}`}
            type={type}
            {...comment}
          />
        ))}

        {loading && <div className="w-full h-full text-center" />}
      </div>
    </InfiniteScroller>
  );
};

export default CommentList;
