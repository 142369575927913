/** 收藏期刊按钮 */

'use client';

import { useEffect, useState } from 'react';

import { useShallow } from 'zustand/react/shallow';

import IconStyle from '@/components/Icon/heart.module.css';

import { IconHeart } from '@/components';
import { apiCollect } from '@/services';
import { useAudioStore } from '@/store';

interface Props {
  /** 是否显示文案 */
  showText?: boolean;
  /** 期刊 id */
  id: string;
  active: boolean;
  count?: number;
  text?: string;
  /** 收藏类型  0:歌曲，1:期刊，2:关注，3:黑名单 */
  collectType: CollectType;
  iconPosition?: 'left' | 'right';
  className?: string;
  textClassName?: string;
  /** 文字跟图标之间的间隔  单位px */
  gap?: number;
  color?: string;
}

export default function CollectButton({
  id,
  active,
  count = 0,
  text = '',
  collectType,
  iconPosition = 'left',
  gap = 9,
  className = '',
  textClassName = '',
  showText = false,
  color,
}: Props) {
  const { playList, playQueue, updatePlayList, setPlayQueue } = useAudioStore(
    useShallow((state) => {
      return {
        playList: state.playList,
        playQueue: state.playQueue,
        updatePlayList: state.updatePlayList,
        setPlayQueue: state.setPlayQueue,
      };
    }),
  );

  const [haveCollect, setHaveCollect] = useState<boolean>(false); // 收藏状态
  const [currentCount, setCurrentCount] = useState<number>(0); // 收藏数量
  const [animate, setAnimate] = useState<boolean>(false); // 动画状态

  /**
   * @desc 收藏/取消收藏 (先改变状态，后根据结果回退版本)
   */
  // const handleCollect = async (event: React.MouseEvent) => {
  //   event.stopPropagation();
  //   const state = haveCollect;

  //   setHaveCollect(!state); // 更新收藏状态
  //   setCurrentCount((currentCount) => currentCount + (!state ? 1 : -1));

  //   const res = await apiCollect({ isAdd: !state, objectId: id, collectType });
  //   // 如果请求失败，回退状态
  //   if (res.code !== 200) {
  //     setHaveCollect(state);
  //     setCurrentCount((currentCount) => currentCount + (state ? 1 : -1));
  //   }
  // };

  // 收藏/取消收藏
  const handleCollect = async (event: React.MouseEvent) => {
    event.stopPropagation();
    const preState = haveCollect; // 旧状态
    const res = await apiCollect({ isAdd: !preState, objectId: id, collectType });
    if (res.code === 200) {
      /**
       * 1. 更新动画状态 不直接用 haveCollect 控制动画，是为了防止初始化时触发动画
       * 2. 更新收藏状态
       * 3. 更新收藏数量
       * 4. 如果操作的是当前正在播放的单曲，改变 store 中的状态，为了播放器收藏状态同步
       */
      setAnimate(!preState);
      setHaveCollect(!preState);
      setCurrentCount((currentCount) => currentCount + (!preState ? 1 : -1));
      dispatchStateToPlayer(!preState);
    }
  };

  // 将状态派发给播放器
  const dispatchStateToPlayer = (state: boolean) => {
    if (collectType !== '0') return; // 不是单曲

    const newPlayList = playList.map((item) => ({
      ...item,
      haveCollect: item.id === id ? state : item.haveCollect,
    }));
    updatePlayList(newPlayList);

    const newPlayQueue = playQueue.map((item) => ({
      ...item,
      haveCollect: item.id === id ? state : item.haveCollect,
    }));
    setPlayQueue(newPlayQueue);
  };

  useEffect(() => {
    setHaveCollect(active);
  }, [active]);

  useEffect(() => {
    setCurrentCount(count);
  }, []);

  return (
    <div
      className={`flex flex-row items-center gap-[${gap}px] w-fit h-fit cursor-pointer group ${IconStyle['collect-button']} ${className}`}
      onClick={handleCollect}
    >
      {iconPosition === 'left' && <IconHeart active={haveCollect} animateState={animate} color={color} />}
      {/* 文案 */}
      {!!(showText && currentCount > 0) && (
        <p
          className={`${haveCollect ? 'text-brand' : 'text-[rgba(0,0,0,0.4)]'} group-hover:text-brand transition-colors text-[13px] leading-[18.2px] ${textClassName}`}
        >
          {`${currentCount || ''}${text || ''}`}
        </p>
      )}
      {/* 图标 */}
      {iconPosition === 'right' && <IconHeart active={haveCollect} animateState={animate} color={color} />}
    </div>
  );
}
