import Copyright from './Copyright';
import MiniFooterSocial from './Social/MiniFooterSocial';

export default function Footer({ className }: { className?: string }) {
  return (
    <div className={`h-[160px] ${className}`}>
      <div className="w-[1200px] mx-auto">
        {/* 社交账号 */}
        <MiniFooterSocial className="pt-[41px]" />
        {/* 备案信息 */}
        <Copyright className="mt-15px" />
      </div>
    </div>
  );
}
