/** 歌词 */
interface Props {
  lrcData: string;
  className?: string;
}

import styles from './index.module.css';

export default function Lrc({ lrcData, className }: Props) {
  const lrcList = lrcData.split('\n');

  const normalClassName = 'text-[14px] leading-[36.4px] text-[rgba(0,0,0,0.7)]'; // 普通歌词样式
  const currentClassName = 'text-[17px] leading-[44.2px] text-[rgba(0,0,0,0.95)]'; // 当前歌词样式

  return (
    <div className={`overflow-y-auto pr-14px ${styles.lrc} ${className}`}>
      {lrcData ? (
        <div className="h-auto">
          {lrcList.map((item, index) => (
            <p key={index} className={true ? normalClassName : currentClassName}>
              {item}
            </p>
          ))}
        </div>
      ) : (
        <div className={`flex justify-center items-center relative h-full`}>
          <div className="text-[14px] text-[rgba(0,0,0,0.7)]">暂无歌词</div>
        </div>
      )}
    </div>
  );
}
