import Image from 'next/image';

export default function JournalRecommendCard({ title, image, userCollectCount }: JournalInfo) {
  return (
    <div className="flex flex-row items-center h-[56px] group">
      <div className="w-[80px] h-[56px] rounded-[3px] overflow-hidden">
        <Image
          src={image}
          alt={title}
          width={80}
          height={56}
          unoptimized
          className={`w-[80px] h-[56px] group-hover:transform-scale-105 transition-transform-300`}
        />
      </div>
      <div className="flex flex-col justify-between h-full ml-[20px] py-[6px]">
        <p className="w-[200px] text-[15px] leading-[21px] font-500 text-overflow cursor-pointer">{title}</p>
        <p className="text-[rgba(0,0,0,0.7)] text-[13px] leading-[18.2px] cursor-pointer">{`${userCollectCount}人收藏`}</p>
      </div>
    </div>
  );
}
