import serverHttp from '@/utils/request/server';

/**
 * @description 获取支持的手机号国家码
 */
// export const apiGetSupportedCountryCode = async () => {
//   const res = await request('/user/user/supportedCountryCode');
//   return verifyResponse(res);
// };

/**
 * @description 获取贡献者
 */
export const apiThanks = async (): Promise<FetchResponse<UserInfo[]>> => {
  const request = new Request('http://localhost', {
    method: 'GET',
    headers: {
      requestUrl: '/user/my/thanks',
    },
  });
  const res = await serverHttp.get(request);
  return res;
};
