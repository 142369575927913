import { Avatar } from '@/components/';

import { ContributorCardType } from '../app/(website)/about/page';

export default function ContributorCard({ nickName, avatar, contributorRole }: ContributorCardType) {
  return (
    <div className="flex flex-col items-center w-[178px] h-[260px] pt-[36px] pb-[44px] rounded-[6px] bg-[#fff]">
      <Avatar size={92} src={avatar} alt={`${nickName}-avatar`} />
      <p className="w-full mt-[31px] mb-[11px] px-[20px] text-[18px] leading-[25px] text-[rgba(0,0,0,0.95)] text-center  overflow-hidden whitespace-nowrap truncate">
        {nickName}
      </p>
      <p className="text-[15px] leading-[18px] text-[rgba(0,0,0,0.4)] text-center">{contributorRole}</p>
    </div>
  );
}
