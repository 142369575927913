'use client';

import Link from 'next/link';

interface IProps {
  no: string;
  onClick: () => void;
}

export default function VolTag({ no, onClick }: IProps) {
  return (
    <div>
      <Link
        className="block w-fit mt-24px py-5px px-12px rounded-15px bg-[rgba(0,0,0,0.05)] text-[rgba(0,0,0,0.7)] hover:text-brand text-11px leading-15px font-bold cursor-pointer transition-color-200"
        href={`/vol/${no}`}
      >
        <div onClick={onClick}>{`VOL.${no.toString().padStart(4, '0')}`}</div>
      </Link>
    </div>
  );
}
