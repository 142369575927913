import style from './index.module.css';
import Journal from './widget/Journal';
import NoData from './widget/NoData';
import Single from './widget/Single';

interface IProps {
  journalData: JournalInfo[];
  keyword: string;
  songData: SongInfo[];
  className?: string;
  onClose: () => void;
}

export default function SearchDropdown({ journalData, keyword, songData, className, onClose }: IProps) {
  const haveData = journalData.length || songData.length;

  return (
    <div
      className={`relative w-747px h-fit max-h-747px rounded-10px mt-10px pt-24px pb-40px pl-36px pr-18px bg-base border-0.5px border-#e1e1e1 overflow-y-auto z-11 ${style.dropdownScrollbar} ${className}`}
      style={{ boxShadow: '0px 6px 34px 0px rgba(0, 0, 0, 0.1)' }}
    >
      {haveData ? (
        <div className="h-fit">
          {!!songData.length && <Single keyword={keyword} data={songData} />}
          {!!journalData.length && <Journal keyword={keyword} data={journalData} onClose={onClose} />}
        </div>
      ) : (
        <NoData className="w-full h-160px" />
      )}
    </div>
  );
}
