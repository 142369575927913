import Image from 'next/image';

import styles from './index.module.css';

function VolNo({ text, className }: { text: string; className?: string }) {
  return (
    <div className={`w-48px h-48px pt-8px rounded-2px bg-#fff/90 z-2 ${className}`}>
      <p className="w-full h-15px ml-5px text-15px font-normal leading-15px text-#000/90 text-left">
        {text.toString().padStart(4, '0')}
      </p>
      <div className="w-14px h-1.4px my-3px ml-6px bg-brand" />
      <p className="ml-6px text-9px leading-10px text-#000/40">VOL</p>
    </div>
  );
}

export default function JournalCard({
  image,
  title,
  journalNo,
  scale = false,
  children,
  className,
}: {
  image: string;
  journalNo: string;
  title?: string;
  scale?: boolean;
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    /* banner container */
    <div className={`relative w-712px h-420px rounded-6px overflow-hidden bg-[url(${image})] group ${className}`}>
      {/* 左上方 角标 */}
      <VolNo className="absolute top-16px left-16px" text={journalNo} />
      {/* banner */}
      <Image
        width={712}
        height={420}
        src={image}
        unoptimized
        alt={`Vol${journalNo}`}
        className={`absolute top-50% left-0 transform-translate-y-[-50%] w-full h-auto object-cover object-c ${scale && 'group-hover:transform-scale-103 transition-transform-300'}`}
      />
      {/* 标题 */}
      {!!title && (
        <div className={`absolute bottom-0 w-full h-126px ${styles.title_background}`}>
          <p className="absolute bottom-23px left-23px text-#fff text-20px leading-34px">{title}</p>
        </div>
      )}
      {children}
    </div>
  );
}
