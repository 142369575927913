import Link from 'next/link';

import JournalRecommendCard from './JournalRecommendCard';

interface Props {
  list: JournalInfo[];
}

export default function JournalRecommendList({ list }: Props) {
  return (
    <div className="flex flex-col">
      {/* 分类 & 电台 */}
      <div className="text-17px leading-24px font-500">热门推荐</div>
      {/* 期刊 list */}
      <div className="flex flex-col gap-30px mt-12px">
        {!!list?.length &&
          list.map((item: JournalInfo) => (
            <Link key={item.id} href={`/vol/${item.journalNo}`}>
              <JournalRecommendCard key={item.id} {...item} />
            </Link>
          ))}
      </div>
    </div>
  );
}
