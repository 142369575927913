'use client';

import { useEffect } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { autoLogin } from '@/hooks';
import { useUserStore } from '@/store';

export default function Mounted() {
  const { getUserInfo } = useUserStore(
    useShallow((state) => ({
      getUserInfo: state.getUserInfo,
    })),
  );

  const handleAutoLogin = async () => {
    const res = await autoLogin();
    if (res) {
      await getUserInfo();
    }
  };

  // 身份验证
  useEffect(() => {
    handleAutoLogin();
  }, []);

  return <div></div>;
}
