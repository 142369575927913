import VolPlayButton from '@/components/Journal/VolPlayButton';

import { JournalCard } from '@/components';

export default function CoverCard({ journalInfo, songList }: { journalInfo: JournalInfo; songList: SongInfo[] }) {
  const { image, journalNo } = journalInfo;

  return (
    <div className="relative">
      <JournalCard image={image} journalNo={journalNo} />
      <VolPlayButton className="absolute left-30px bottom-30px" journalInfo={journalInfo} songList={songList} />
    </div>
  );
}
