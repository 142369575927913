import { jwtDecode } from 'jwt-decode';

import { apiUserLogin, apiAutoLogin } from '@/services';
import { setAccessToken, getAuthorization, clearAccessToken, getDeviceId } from '@/utils';

export const saveToken = async (token: string) => {
  const { exp } = parseJWT(token);
  setAccessToken({ token, expires: exp.toString() });
};

export const SmsLogin = async ({
  phone,
  authCode,
  deviceId,
}: {
  phone: string;
  authCode: string;
  deviceId: string;
}) => {
  // 登录成功后，将token存入localStorage
  const result = await apiUserLogin({ mobile: phone, mobileCheckCode: authCode, deviceId });
  if (result.code === 200) {
    saveToken(result.data);
  }
  return result;
};

export const autoLogin: () => Promise<boolean> = async () => {
  const token = await getAuthorization();
  if (!token) {
    // 如果没有 token，则清空 localStorage，不往下执行
    localStorage.clear();
    return false;
  }

  // 如果有 token，则触发 autoLogin 接口
  const deviceId = getDeviceId();
  const result = await apiAutoLogin({ deviceId: deviceId });
  if (result.code === 200) {
    const jwt = result.data;
    const { exp } = parseJWT(jwt);
    setAccessToken({ token: jwt, expires: exp.toString() });
    return true;
  } else {
    logout();
    return false;
  }
};

export const useLogin = () => {
  return { SmsLogin };
};

export const logout = () => {
  clearAccessToken();
  localStorage.clear();
};

// format jwt token
export const parseJWT = (token: string): { sub: number; avatar: number; exp: number } => {
  try {
    return jwtDecode(token);
  } catch (err) {
    return { sub: 0, avatar: 0, exp: 0 };
  }
};
