import Link from 'next/link';

import { agreementList } from './data';

export default function Copyright({ className }: { className?: string }) {
  return (
    <div className={`w-[1200px] mx-auto text-[rgba(255,255,255,0.4)] text-[12px] leading-[17px] ${className}`}>
      {/* 备案信息 */}
      <p className="w-fit mt-[25px] mx-auto mb-[6px]">
        <Link
          className="hover:text-[rgba(255,255,255,0.7)]"
          href="https://beian.miit.gov.cn/#/Integrated/index/"
          target="_blank"
        >
          粤ICP备2024190175号-1
        </Link>
        &nbsp;&nbsp;&nbsp;
        <Link
          className="hover:text-[rgba(255,255,255,0.7)]"
          href="https://beian.mps.gov.cn/#/query/webSearch?code=44030002002777"
          target="_blank"
        >
          粤公网安备44030002002777号
        </Link>
        &nbsp;&nbsp;&nbsp;
        <Link className="hover:text-[rgba(255,255,255,0.7)]" href="https://cdn2.indie.cn/indie/lic.jpg" target="_blank">
          粤网文〔2024〕4343-312号
        </Link>
        &nbsp;&nbsp;&nbsp;
        <span>深圳雀乐文化科技有限责任公司 Shenzhen QueYue Culture Technology Co., Ltd.</span>
      </p>

      <p className="w-fit mx-auto mt-[5px]">
        公司地址:深圳市南山区粤海街道海珠社区后海滨路 3368 号鹏润达商业广场 28F71
      </p>

      {/* 协议 */}
      <p className="w-fit mx-auto mt-[5px]">
        {agreementList.map(({ name, url }) => (
          <Link className="mx-[4px] hover:text-[rgba(255,255,255,0.7)]" href={url} key={name} target="_blank">
            {name}
          </Link>
        ))}
      </p>
    </div>
  );
}
