// 期刊
// import { request, verifyResponse } from '@/utils';
import serverHttp from '@/utils/request/server';

/**
 * @description 查询期刊信息 默认10条
 * @categoryId 期刊分类
 * @journalNoRange 期刊号范围
 * @pageNum 页码
 * @pageSize 每页条数
 */
export const apiJournalList: (params: {
  categoryId?: string;
  journalNoRange?: string;
  pageNum?: number;
  pageSize?: number;
}) => Promise<FetchResponse<Paging<JournalInfo>>> = async ({
  categoryId = '',
  journalNoRange = '',
  pageNum = 1,
  pageSize = 10,
}) => {
  const request = new Request(
    `http://localhost?categoryId=${categoryId}&journalNoRange=${journalNoRange}&pageNum=${pageNum}&pageSize=${pageSize}`,
    {
      method: 'GET',
      headers: {
        requestUrl: '/music/journal/list',
      },
    },
  );
  const res = await serverHttp.get(request);
  return res;
};

/**
 * @description 查询标签信息
 */
export const apiSearchCategoryList: () => Promise<FetchResponse<CategoryType[]>> = async () => {
  const request = new Request('http://localhost', {
    method: 'GET',
    headers: {
      requestUrl: '/music/search/category',
    },
  });
  const res = await serverHttp.get(request);
  return res;
};

/**
 * @description 根据收藏数推荐8期
 * @id 期刊号
 */
export const apiGetJournalRecommendWithCollect: () => Promise<FetchResponse<JournalInfo[]>> = async () => {
  const request = new Request('http://localhost', {
    method: 'GET',
    headers: {
      requestUrl: `/music/journal/recommend`,
    },
  });
  const res = await serverHttp.get(request);
  return res;
};

/**
 * @description 根据当前期刊的期刊号推荐6期类似期刊
 * @id 期刊号
 */
export const apiGetJournalRecommendById: (params: {
  id: string;
  limit: number;
}) => Promise<FetchResponse<JournalInfo[]>> = async ({ id, limit }) => {
  const request = new Request('http://localhost', {
    method: 'GET',
    headers: {
      requestUrl: `/music/journal/recommend/${id}/${limit}`,
    },
  });
  const res = await serverHttp.get(request);
  return res;
};

/**
 * @description 根据期刊号查询期刊信息
 * @id 期刊号
 */
export const apiGetJournalInfoById: (params: { id: string }) => Promise<FetchResponse<JournalInfo>> = async ({
  id,
}) => {
  const request = new Request('http://localhost', {
    method: 'GET',
    headers: {
      requestUrl: `/music/journal/journalNo/${id}`,
    },
  });
  const res = await serverHttp.get(request);
  return res;
};

/**
 * @description 根据期刊号查询歌曲 (若为游客，期刊号须在最新10期内)
 * @id 期刊号
 */
export const apiGetSongsByJournalNo: (params: { id: string }) => Promise<FetchResponse<SongInfo[]>> = async ({
  id,
}) => {
  const request = new Request('http://localhost/', {
    method: 'GET',
    headers: {
      requestUrl: `/music/song/getByJournalNo/${id}`,
    },
  });
  const res = await serverHttp.get(request);
  return res;
};

export const apiGetTags: () => Promise<FetchResponse<TagInfo[]>> = async () => {
  const request = new Request('http://localhost/', {
    method: 'GET',
    headers: {
      requestUrl: '/music/search/tag',
    },
  });
  const res = await serverHttp.get(request);
  return res;
};

export const apiCarousel: () => Promise<FetchResponse<Carousel[]>> = async () => {
  const request = new Request('http://localhost/', {
    method: 'GET',
    headers: {
      requestUrl: '/user/index/carousel',
    },
  });
  const res = await serverHttp.get(request);
  return res;
};
