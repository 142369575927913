import Image from 'next/image';

import { CollectButton, IconEqualizer, HighlightText } from '@/components';

interface Props extends SongInfo {
  /** 播放状态 */
  playState: boolean;
  /** 展示收藏按钮 */
  showCollect: boolean;
  /** 显示均衡器效果 */
  showEq: boolean;
  keyword?: string;
  onPlay: (id: string) => void;
}

export default function SongCard({
  playState,
  id,
  title,
  pic,
  artist,
  album,
  showCollect,
  haveCollect,
  duration,
  showEq,
  keyword,
  onPlay,
}: Props) {
  return (
    <div
      className="flex flex-row items-center justify-between w-full h-72px my-3px py-12px px-18px rounded-3px hover:bg-#f2f3f7 group cursor-pointer"
      onClick={() => onPlay(id)}
    >
      {/* left */}
      <div className="flex flex-row items-center w-full">
        {/* 专辑封面 */}
        <Image
          width={48}
          height={48}
          src={pic}
          alt={title}
          className="flex-shrink-0 w-48px h-48px rounded-3px overflow-hidden"
          unoptimized
        />

        {/* 歌曲名称/歌手 */}
        <div className={`flex flex-col justify-between ml-15px`}>
          <HighlightText
            text={title}
            keyword={keyword ?? ''}
            className={`w-390px text-15px leading-21px text-base group-hover:text-brand flex-grow-1 text-overflow ${showEq && 'text-brand'}`}
          />
          <HighlightText
            text={`${artist}/${album}`}
            keyword={keyword ?? ''}
            className={`w-390px text-13px leading-18.2px text-base group-hover:text-brand flex-grow-1 text-overflow ${showEq && 'text-brand'}`}
          />
        </div>
      </div>

      {/* right */}
      <div className="flex flex-row items-center">
        {/* 均衡器效果 */}
        {showEq && <IconEqualizer active={playState} />}

        {/* 音频时长 */}
        <p className="ml-30px mr-13px text-12px leading-17px text-#000/40">{duration || '00:00'}</p>

        {/* 收藏按钮单曲 */}
        {showCollect && (
          <CollectButton
            id={id}
            active={haveCollect}
            collectType="0"
            textClassName="w-42px ml-6px mr-24px text-14px leading-16px text-overflow"
          />
        )}
      </div>
    </div>
  );
}
