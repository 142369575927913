// 均衡器效果

import styles from './equalizer.module.css';

export default function Equalizer({ active }: { active: boolean }) {
  return (
    <div className={`${styles.equalizer} ${!active && styles.paused}`}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}
