'use client';

import { useEffect } from 'react';

import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import Player from './Player';
import PlayerCard from './PlayerCard/PlayerCard';

import { useAudioStore } from '@/store';

const PlayerBar = ({ className }: { className?: string }) => {
  const pathname = usePathname();

  const { audioId, showCard, setShowCard } = useAudioStore(
    useShallow((state) => {
      return {
        audioId: state.audioId,
        // useShowPlayer: state.useShowPlayer,
        showCard: state.showCard,
        setShowCard: state.setShowCard,
      };
    }),
  );
  // const showPlayer = useShowPlayer();

  useEffect(() => {
    if (showCard) setShowCard(false);
  }, [pathname]);

  useEffect(() => {
    const footerDom: HTMLElement | null = document.querySelector('#footer_global');
    if (!footerDom) return;
    footerDom.style.paddingBottom = audioId || showCard ? '80px' : '0';
  }, [audioId, showCard]);

  return (
    <div className={`fixed bottom-0 w-100vw h-auto z-10 ${className}`}>
      {/* 播放器 */}
      <div
        className="fixed bottom-0 w-full h-[90px] bg-[#fff] bg-op-80 backdrop-blur-[20px] shadow-lg shadow-black-[0_0_10px] z-10 transition-bottom duration-600`"
        style={{ bottom: audioId || showCard ? 0 : -80 }}
      >
        <Player className="mx-auto" onSwitchShowCard={() => setShowCard(!showCard)} />
      </div>
      {/* 单曲卡片 */}
      <PlayerCard show={showCard} className="fixed left-0 bottom-0 w-100vw h-100vh" />
    </div>
  );
};

export default dynamic(() => Promise.resolve(PlayerBar), { ssr: false });
