'use client';

/**
 * @description 在播放器 card 中，点击链接需要关闭 card，但如果遇到 Link 为当前路由，会无法关闭
 */

import { useRouter } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import { useAudioStore } from '@/store';

export default function ClientLink({ href, children }: { href: string; children: React.ReactNode }) {
  const router = useRouter();
  const { showCard, setShowCard } = useAudioStore(
    useShallow((state) => {
      return {
        showCard: state.showCard,
        setShowCard: state.setShowCard,
      };
    }),
  );

  const handleToLink = (href: string, event: React.MouseEvent) => {
    event.preventDefault();

    if (showCard) setShowCard(false);
    if (href) router.push(href);
  };

  return (
    <a href={href} onClick={(e) => handleToLink(href, e)}>
      {children}
    </a>
  );
}
