'use client';

import { useRouter } from 'next/navigation';

export default function PlayButton({ no, className }: { no: string; className?: string }) {
  const router = useRouter();
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    router.push(`/vol/${no}?autoPlay=1`);
  };

  return (
    <div
      className={`w-[46px] h-[32px] bg-[url(/img/icon/play-journal-card.svg)] bg-no-repeat z-2 ${className}`}
      onClick={handleClick}
    />
  );
}
