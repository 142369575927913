/**
 * 歌曲卡片
 */
'use client';

import { useShallow } from 'zustand/react/shallow';

import { SongCard } from '@/components';
import { useAudioStore } from '@/store';

interface Props {
  /**
   * @description 列表类型
   * vol: 期刊
   * collectSingle: 收藏单曲
   * playerCard: 播放器内
   */
  listInfo: { type: 'vol' | 'collectSingle' | 'playerCard'; id: string | null };
  // 高亮关键词
  keyword?: string;
  /**
   * @description 点击单曲事件
   * playList: 播放当前列表
   * playPush: push 到歌单
   */
  clickType?: 'playList' | 'playPush';
  songList: SongInfo[];
  /** 收藏按钮的展示逻辑 always: 总是展示 playing: 播放时展示; none: 不展示*/
  collectType?: 'always' | 'playing' | 'none';
  className?: string;
}

export default function SongCardList({
  listInfo,
  songList,
  className,
  keyword,
  collectType = 'always',
  clickType = 'playList',
}: Props) {
  const {
    playState,
    audioId,
    playListInfo,
    playList,
    playQueue,
    setPlayState,
    setPlayListInfo,
    setPlayQueue,
    setPlayList,
    setAudioId,
  } = useAudioStore(
    useShallow((state) => ({
      playState: state.playState,
      audioId: state.audioId,
      playListInfo: state.playListInfo,
      playList: state.playList,
      playQueue: state.playQueue,
      setPlayState: state.setPlayState,
      setPlayListInfo: state.setPlayListInfo,
      setPlayList: state.setPlayList,
      setPlayQueue: state.setPlayQueue,
      setAudioId: state.setAudioId,
    })),
  );

  const handlePlay = (id: string) => {
    if (!id) return;

    // 如果是播放中的歌曲，改变播放状态
    if (id === audioId) {
      setPlayState(!playState);
      return;
    }

    // 如果选择的歌曲在队列中，直接切歌
    const isPlayingList = listInfo.type === playListInfo.type && listInfo.id === playListInfo.id; // 正在播放当前歌单
    const isInQueue = playQueue.some((item) => item.id === id); // 所选的歌在队列中

    if ((isPlayingList && isInQueue) || listInfo.type === 'playerCard') {
      setAudioId(id);
      return;
    }

    // 修改 playList、playQueue
    if (clickType === 'playList') {
      handlePlayList(id);
    } else if (clickType === 'playPush') {
      handlePlayPush(id);
    }
  };

  const handlePlayList = (id: string) => {
    // 重新设置播放列表
    const res = setPlayListInfo(listInfo as IPlayListInfo);
    if (!res) return;
    setPlayList(songList);
    setAudioId(id);
  };

  const handlePlayPush = (id: string) => {
    const newAudio: SongInfo | null = songList.find((item) => item.id === id) ?? null;
    if (!newAudio) return;

    if (playListInfo.type !== 'fm') setPlayList([...playList, newAudio]);
    setPlayQueue([...playQueue, newAudio]);
    setAudioId(id);
  };

  return (
    <div className={`${className}`}>
      {songList.map((item: SongInfo) => (
        <SongCard
          key={item.id}
          {...item}
          playState={playState}
          showEq={item.id === audioId}
          onPlay={(audioId: string) => handlePlay(audioId)}
          keyword={keyword}
          // showCollect={collectType === 'always' || (collectType === 'playing' && item.id === audioId)}
          showCollect={collectType === 'always' || collectType === 'playing'}
        />
      ))}
    </div>
  );
}
