// 播放器按钮

'use client';

interface Props {
  onClick: () => void;
  className?: string;
  children?: React.ReactNode;
}

export default function ControlButton({ children, className, onClick }: Props) {
  return (
    <a
      href="#"
      className={`block w-fit h-fit ${className}`}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      {!!children && children}
    </a>
  );
}
