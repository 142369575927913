import Copyright from './Copyright';
import FooterDownload from './Download/FooterDownload';
import FooterPlatform from './FooterPlatform';
import FooterSocial from './Social/FooterSocial';

export default function Footer({ className }: { className?: string }) {
  return (
    <div className={`h-320px pt-48px ${className}`}>
      <div className="w-1200px mx-auto">
        <div className="flex flex-row">
          {/* 开放平台 */}
          <FooterPlatform />
          {/* 社交账号 */}
          <FooterSocial className="ml-126px" />
          {/* 下载客户端 */}
          <FooterDownload className="ml-110px" />
        </div>

        <Copyright className="mt-60px" />
      </div>
    </div>
  );
}
