'use client';

import cloneDeep from 'lodash/cloneDeep';
import qs from 'qs';

import { openLogin } from '@/components/Login/LoginContext';
import { toast } from '@/components/ui/use-toast';

import { IQueryParams, IResponse } from '@/utils/request/type';

import { checkAuthOffline, logout } from '@/utils';

const defaultHost = process.env.NEXT_PUBLIC_HOST;
const aiHost = process.env.NEXT_PUBLIC_AI_HOST;
export const hostMap = {
  noToken: defaultHost,
  default: defaultHost,
  ai: aiHost,
};

export async function handleResponse<T>(response: globalThis.Response): Promise<IResponse<T>> {
  // 可以把浏览器状态码错误直接抛出错误，也可以配置一个map文件，状态码直接匹配自定义的文本并且弹消息提醒
  // if (!response.ok) throw new Error(response.statusText);
  // 这里直接将浏览器抛出的异常信息处理成和后端抛出的信息格式一致
  if (!response.ok) {
    const resData = await response.json();
    checkResponseState(resData);
    return {
      message: resData.message,
      data: null,
      code: resData.code,
    } as IResponse<T>;
  }
  const contentType = response.headers.get('content-type');
  // 如果是json格式调用json解析
  if (contentType && contentType.includes('application/json')) {
    const res = await response.json();
    checkResponseState(res);
    return res;
  }
  return {
    code: response.status,
    data: response.text(),
    message: response.statusText,
  } as IResponse<T>;
}

/**
 * @description 统一处理响应状态码
 * @param response 请求结果
 */
export const checkResponseState = async (response: FetchResponse<any>) => {
  if (response.code === 200) return;

  const { code, message } = response;

  if (![400, 401, 10000].includes(code)) {
    toast({
      description: message,
      duration: 1500,
    });
  }

  /**
   * 退出登录，重新登录
   * 401: 暂未登录或token已经过期
   * 403: 没有相关权限
   * 10000: 用户模块错误
   */
  if ([400, 401, 10000].includes(code)) {
    // 判断是否有 token
    if (checkAuthOffline()) {
      // 身份校验失败，请 xxx
      logout();
      window.location.reload();
    } else {
      localStorage.clear();
    }

    // 打开登录框
    openLogin();
    return;
  }
};

export const getStringParams = (params: IQueryParams) => {
  // 深克隆一下，以免传来的参数是redux等里面的数据，修改会报错
  const paramsCopy = cloneDeep(params);
  for (const key in paramsCopy) {
    if (paramsCopy[key] === '' || paramsCopy[key] === undefined) {
      paramsCopy[key] = null;
    }
  }
  return qs.stringify(paramsCopy, { skipNulls: true });
};

export const createFormBody: (formDataObject: any) => string = (formDataObject: any) => {
  const encodedForm = new URLSearchParams();
  for (const key in formDataObject) {
    if (formDataObject.hasOwnProperty(key)) {
      encodedForm.append(key, formDataObject[key]);
    }
  }
  return encodedForm.toString();
};
