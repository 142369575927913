export default function CommentHeader({
  type,
  count,
  clssName,
  onChangeType,
}: {
  type: 'hot' | 'new';
  count: string;
  clssName: string;
  onChangeType: (value: 'hot' | 'new') => void;
}) {
  return (
    <div className={`flex flex-row items-center justify-between ${clssName}`}>
      <div className="flex flex-row items-center text-[15px] leading-[21px] gap-[12px]">
        <div className="text-base">全部评论</div>
        <div className="text-[rgba(0,0,0,0.4)]">{`${count}条`}</div>
      </div>

      <div className="flex flex-row items-center text-[14px] leading-[19.6px] gap-[12px]">
        <div
          className={`${type === 'hot' ? 'text-base' : 'text-[rgba(0,0,0,0.4)] hover:text-brand cursor-pointer'}`}
          onClick={() => onChangeType('hot')}
        >
          热门
        </div>
        <div className="text-[rgba(0,0,0,0.4)] cursor-pointer hover:text-brand">|</div>
        <div
          className={`${type === 'new' ? 'text-base' : 'text-[rgba(0,0,0,0.4)] hover:text-brand cursor-pointer'}`}
          onClick={() => onChangeType('new')}
        >
          最新
        </div>
      </div>
    </div>
  );
}
