'use client';
import LoginForm from './LoginForm';

export default function LoginPhone() {
  return (
    <div className="flex flex-col items-center w-330px bg-#f2f3f7">
      {/* title */}
      <p className="mt-10px text-13px leading-18px text-#000/70">输入手机号，开启雀乐</p>

      {/* form */}
      <LoginForm className="mt-23px w-full" />
    </div>
  );
}
