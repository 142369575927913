import clientHttp from '@/utils/request/client';

/** 查询歌曲信息
 *  @id 歌曲 id
 */
export const apiGetSongInfo = async (id: string) => {
  const result: FetchResponse<SongInfo> = await clientHttp.get(`/queyueapi/music/song/${id}`);
  return result;
};

/** 查询收藏歌曲信息
 *  @pageNum 页码，不传或者小于1，则返回所有收藏歌曲
 *  @pageSize 每页条数，不传或者小于1，则返回所有收藏歌曲
 */
export const apiGetSongCollect = async ({
  pageNum,
  pageSize,
  userId,
}: {
  pageNum: number;
  pageSize: number;
  userId: string;
}) => {
  const result: FetchResponse<Paging<SongInfo>> = await clientHttp.get(
    `/queyueapi/music/song/collect?userId=${userId}&pageNum=${pageNum}&pageSize=${pageSize}`,
  );
  return result;
};

/** 查询收藏期刊信息
 *  @pageNum 页码，不传或者小于1，则返回所有收藏歌曲
 *  @pageSize 每页条数，不传或者小于1，则返回所有收藏歌曲
 */
export const apiGetJournalCollect = async ({
  pageNum,
  pageSize,
  userId,
}: {
  pageNum: number;
  pageSize: number;
  userId: string;
}) => {
  const result: FetchResponse<Paging<JournalInfo>> = await clientHttp.get(
    `/queyueapi/music/journal/collect?userId=${userId}&pageNum=${pageNum}&pageSize=${pageSize}`,
  );
  return result;
};

/** 收藏歌曲
 *  @limit 随机歌曲数，最少1首，最多30首
 */
export const apiGetSongRandom = async (limit: number) => {
  const result: FetchResponse<SongInfo[]> = await clientHttp.get(`/queyueapi/music/song/random/${limit}`);
  return result;
};

/** 查询所有收藏期刊的歌曲信息
 *  pageNum/pageSize不传或者小于1，则返回所有收藏刊的歌曲
 */
export const apiGetSongWithCollectVol = async ({
  pageNum,
  pageSize,
  userId,
}: {
  pageNum: number;
  pageSize: number;
  userId: string;
}) => {
  const result: FetchResponse<Paging<SongInfo>> = await clientHttp.get(
    `/queyueapi/music/journal/collect/song?userId=${userId}&pageNum=${pageNum}&pageSize=${pageSize}`,
  );
  return result;
};

/**
 * @description 搜索期刊
 */
export const apiSearchJournal = async ({
  keyword,
  pageNum,
  pageSize,
}: {
  keyword: string;
  pageNum: number;
  pageSize: number;
}) => {
  const result: FetchResponse<Paging<JournalInfo>> = await clientHttp.get(
    `/queyueapi/music/search/fuzzy/journal?keyword=${keyword}&pageNum=${pageNum}&pageSize=${pageSize}`,
  );
  return result;
};
/**
 * @description 搜索单曲
 */
export const apiSearchSong = async ({
  keyword,
  pageNum,
  pageSize,
}: {
  keyword: string;
  pageNum: number;
  pageSize: number;
}) => {
  const result: FetchResponse<Paging<SongInfo>> = await clientHttp.get(
    `/queyueapi/music/search/fuzzy/song?keyword=${keyword}&pageNum=${pageNum}&pageSize=${pageSize}`,
  );
  return result;
};
