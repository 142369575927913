'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';

import ClientLink from './ClientLink';

export default function Nav({ type = 'server' }: { type?: NextComponentType }) {
  const pathName = usePathname();
  const menuList = [
    {
      name: '首页',
      path: '/',
    },
    {
      name: 'APP下载',
      path: '/download',
    },
    {
      name: '关于我们',
      path: '/about',
    },
  ];

  const pathMatch = (path: string) => {
    if (path === '/') {
      return pathName === '/' || /^\/vol\//.test(pathName) || /^\/music\//.test(pathName);
    }
    return pathName === path;
  };

  return (
    <nav>
      <ul className="flex flex-row items-center space-x-36px">
        {menuList.map(({ name, path }) => (
          <li
            key={path}
            className={`text-15px leading-21px font-500 hover:text-[rgba(0,0,0,0.95)] ${pathMatch(path) ? 'text-[rgba(0,0,0,0.95)]' : 'text-[rgba(0,0,0,0.4)]'}`}
          >
            {type === 'server' ? <Link href={path}>{name}</Link> : <ClientLink href={path}>{name}</ClientLink>}
          </li>
        ))}
      </ul>
    </nav>
  );
}
