export default function Footer() {
  return (
    <div className="absolute bottom-40px left-0 right-0 text-13px leading-18px text-[rgba(0,0,0,0.4)] text-center">
      注册登录即代表同意
      <a
        className="text-[#000]"
        href={`${process.env.NEXT_PUBLIC_MOBILE_HOST}/agreement/registrationAgreement.html`}
        target="_blank"
      >
        《注册协议》
      </a>
      <a
        className="text-[#000]"
        href={`${process.env.NEXT_PUBLIC_MOBILE_HOST}/agreement/privacyPolicy.html`}
        target="_blank"
      >
        《隐私政策》
      </a>
    </div>
  );
}
