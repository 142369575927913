import clientHttp from '@/utils/request/client';

/**
 * @description 喜欢歌曲/收藏期刊/关注/黑名单
 * @objectId 喜欢歌曲/收藏期刊/关注某用户/某用户列入黑名单的id
 * @collectType 0:歌曲，1:期刊，2:关注，3:黑名单
 */
export const apiCollectAdd = async (params: { objectId: string; collectType: CollectType }) => {
  const result: FetchResponse<string> = await clientHttp.post('/queyueapi/user/collect', params);
  return result;
};

/**
 * @description 取消 喜欢歌曲/收藏期刊/关注/黑名单
 * @objectId 喜欢歌曲/收藏期刊/关注某用户/某用户列入黑名单的id
 * @collectType 0:歌曲，1:期刊，2:关注，3:黑名单
 */
export const apiCollectRemove = async ({ objectId, collectType }: { objectId: string; collectType: CollectType }) => {
  const result: FetchResponse<string> = await clientHttp.delete(
    `/queyueapi/user/collect?objectId=${objectId}&collectType=${collectType}`,
  );
  return result;
};

/**
 * @description 喜欢/取消 歌曲/收藏期刊/关注/黑名单
 * @isAdd 是否添加收藏
 * @objectId 喜欢歌曲/收藏期刊/关注某用户/某用户列入黑名单的id
 * @collectType 0:歌曲，1:期刊，2:关注，3:黑名单
 */
export const apiCollect = async ({
  isAdd,
  objectId,
  collectType,
}: {
  isAdd: boolean;
  objectId: string;
  collectType: CollectType;
}) => {
  if (isAdd) {
    return await apiCollectAdd({ objectId, collectType });
  } else {
    return await apiCollectRemove({ objectId, collectType });
  }
};
