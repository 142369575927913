'use client';

import { useState, useRef } from 'react';

import { useShallow } from 'zustand/react/shallow';

import ButtonBar from '../ButtonBar';
import ChildCommentForm from '../ChildComment/ChildCommentForm';
import ChildCommentList from '../ChildComment/ChildCommentList';

import CommentContent from './CommentContent';

import { Avatar } from '@/components';
import { apiCommentThumbup, apiCommentSave } from '@/services';
import { useUserStore } from '@/store';

export default function CommentItem({
  _id,
  avatar,
  userId,
  nickName,
  publishTime,
  location,
  content,
  haveThumbup,
  thumbupCount,
  commentCount,
  topChildrenComment,
  journalId,
  className,
  type,
  onDelete,
}: Comment & { onDelete: (id: string) => Promise<boolean>; className?: string; type: Serv.Comment.Type }) {
  const { userInfo } = useUserStore(
    useShallow((state) => ({
      userInfo: state.userInfo,
    })),
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const [showAllChild, setShowAllChild] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [newCommentList, setNewCommentList] = useState<Comment[]>([]); // 本次新发送的评论

  // 展示回复框
  const handleShowInput = () => {
    setShowInput(true);
    inputRef.current && inputRef.current.focus();
  };

  // 提交回复
  const handleReplySubmit = async (value: string) => {
    const res = await apiCommentSave({ content: value, journalId: journalId, parentId: _id, type });
    if (res.code === 200) {
      setNewCommentList((val) => [res.data, ...val]); // 加到新回复列表，并展示
      setShowInput(false); // 关闭输入框
    }
    return res.code === 200;
  };

  // 给评论点赞
  const handleThumbup = async () => {
    const res = await apiCommentThumbup(_id);
    return res.code === 200;
  };

  return (
    <div className={`${className}`}>
      <div className={`flex flex-shrink`}>
        {/* 主评论内容 */}
        <Avatar size={40} src={avatar} />

        <div className="ml-[15px]">
          <CommentContent nickName={nickName} publishTime={publishTime} location={location} content={content} />

          {/* 子评论 */}
          <ChildCommentList
            parentId={_id}
            showAll={showAllChild}
            topComment={topChildrenComment}
            total={commentCount}
            newCommentList={newCommentList}
          />
        </div>
      </div>

      {/* 按钮 */}
      <ButtonBar
        className={`mt-[12px] ${topChildrenComment ? 'ml-[79px]' : 'ml-[54px]'}`}
        commentCount={commentCount + newCommentList.length}
        newCommentCount={newCommentList.length}
        thumbupCount={thumbupCount}
        haveThumbup={haveThumbup}
        showAll={showAllChild}
        showDelete={userId === userInfo.id}
        onShowInput={handleShowInput}
        onShowAll={() => setShowAllChild((pre) => !pre)}
        onThumbup={handleThumbup}
        onDelete={() => onDelete(_id)}
      />

      {/* 回复 */}
      {showInput && (
        <ChildCommentForm
          nickName={nickName}
          avatar={avatar}
          className="mt-[25px] ml-[55px]"
          onSubmit={(value: string) => handleReplySubmit(value)}
          onClose={() => setShowInput(false)}
        />
      )}
    </div>
  );
}
