import { SongCardList } from '@/components';

interface IProps {
  data: SongInfo[];
  keyword: string;
  className?: string;
}

export default function Single({ data, keyword, className }: IProps) {
  return (
    <div className={className}>
      <p className="text-#000 text-14px leading-20px font-500">单曲</p>
      <SongCardList
        className="w-full mt-6px ml-[-16px]"
        clickType="playPush"
        songList={data}
        keyword={keyword}
        listInfo={{ type: 'collectSingle', id: null }}
        collectType="none"
      />
    </div>
  );
}
