import Link from 'next/link';

interface Props {
  page: number;
  active?: boolean;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  activeClassName?: string;
  disabledClassName?: string;
}

interface ClientProps extends Props {
  type: 'client';
  onClick: (page: number) => void;
}

interface ServerProps extends Props {
  type: 'server';
  link: string;
}

type PaginationItemProps = ClientProps | ServerProps;

function isServerProps(props: PaginationItemProps): props is ServerProps {
  return props.type === 'server';
}

// 通用样式
const commonClassName =
  'w-[40px] h-[40px] rounded-[3px] text-[14px] text-center text-[#000] hover-text-brand leading-[40px] font-500 bg-[#F2F3F7]';

// 激活状态样式
const activeClassName = 'text-[#fff] bg-brand';

// 不可用状态样式
const disabledClassName = 'cursor-default';

export default function PaginationItem(props: PaginationItemProps) {
  return (
    <li
      className={`${props.className || commonClassName} ${props.active && (props.activeClassName || activeClassName)} ${props.disabled && (props.disabledClassName || disabledClassName)}`}
    >
      {isServerProps(props) &&
        (props.active || props.disabled ? (
          <div className="w-full h-full cursor-default">{props.children || props.page}</div>
        ) : (
          <Link href={`${props.link}/${props.page}`} className="block w-full h-full" disabled={props.disabled}>
            {props.children || props.page}
          </Link>
        ))}

      {props.type === 'client' && (
        <div
          className={`w-full h-full cursor-pointer`}
          onClick={() => {
            if (!props.disabled && !props.active) props.onClick?.(props.page);
          }}
        >
          {props.children || props.page}
        </div>
      )}
    </li>
  );
}
