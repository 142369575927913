// 格式化歌词时间
export const secondToDate: (v: number) => string = (second) => {
  const hourNum = Math.floor(second / 3600);
  const minuteNum = Math.floor((second / 60) % 60);
  const secondNum = Math.floor(second % 60);

  const h = hourNum ? `${hourNum}:` : '';
  const m = minuteNum ? `${unitAddZero(minuteNum)}:` : `00:`;
  const s = unitAddZero(secondNum);
  return `${h}${m}${s}`;
};

// 格式化个位数到两位数
export const unitAddZero: (v: number) => string = (v) => {
  return v < 10 ? `0${v}` : `${v}`;
};
