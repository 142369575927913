export default function Close() {
  return (
    <svg className="group" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className="stroke-[rgba(0,0,0,0.95)] group-hover:stroke-brand"
        d="M5 5L19 19"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke-[rgba(0,0,0,0.95)] group-hover:stroke-brand"
        d="M5 19L19 5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
