'use client';

import { useRef, useState, useEffect } from 'react';

import debounce from 'lodash/debounce';

import Dropdown from './Dropdown';
import Input from './Input';

import { apiSearchJournal, apiSearchSong } from '@/services';

interface IProps {
  className?: string;
}

export default function Search({ className }: IProps) {
  const [keyword, setKeyword] = useState<string>('');
  const [journalData, setJournalData] = useState<JournalInfo[]>([]);
  const [songData, setSongData] = useState<SongInfo[]>([]);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const searchRef = useRef<HTMLDivElement>(null);

  const handleInput: (keyword: string) => void = (keyword) => {
    setKeyword(keyword);
  };

  const handleSearch = async (keyword: string) => {
    const [journalRes, songRes] = await Promise.all([
      apiSearchJournal({ keyword, pageNum: 1, pageSize: 20 }),
      apiSearchSong({ keyword, pageNum: 1, pageSize: 20 }),
    ]);

    if (journalRes.code === 200) {
      setJournalData(journalRes.data.rows);
    } else {
      setJournalData([]);
    }

    if (songRes.code === 200) {
      setSongData(songRes.data.rows);
    } else {
      setSongData([]);
    }
  };

  // input 聚焦时，展示下拉框
  const handleInputFocus = () => {
    if (keyword.trim()) setShowDropDown(true);
  };

  const handleClickOutside = (e: Event) => {
    if (searchRef.current && !searchRef.current.contains(e.target as Node)) {
      setShowDropDown(false);
    }
  };

  useEffect(() => {
    if (!keyword.trim()) {
      setSongData([]);
      setJournalData([]);
      setShowDropDown(false);
      return;
    } else {
      setShowDropDown(true);
      handleSearch(keyword);
    }
  }, [keyword]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div ref={searchRef} className={`w-208px h-38px ${className}`}>
      <Input onInput={debounce((keyword) => handleInput(keyword), 200)} onFocus={handleInputFocus} />

      {showDropDown && (
        <Dropdown
          keyword={keyword}
          journalData={journalData}
          songData={songData}
          onClose={() => setShowDropDown(false)}
        />
      )}
    </div>
  );
}
