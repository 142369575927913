'use client';

import { useEffect, useState } from 'react';

interface Iprops {
  text: string;
  keyword: string;
  className?: string;
}

const HighlightText = ({ text, keyword, className }: Iprops) => {
  // 使用useState钩子来存储处理后的文本
  const [highlightedText, setHighlightedText] = useState<string>(text);

  useEffect(() => {
    if (!keyword) return;
    // 创建一个正则表达式，全局匹配并忽略大小写
    const regex = new RegExp(keyword, 'gi');
    // 使用replace方法替换所有匹配的关键字，并用span标签包裹起来
    const newText = text.replace(regex, `<span class="text-brand">$&</span>`);
    // 更新状态以触发组件重新渲染
    setHighlightedText(newText);
  }, []); // 仅在text或keyword变化时重新执行effect

  // 渲染带有高亮的文本
  return <p className={className} dangerouslySetInnerHTML={{ __html: highlightedText }} />;
};

export default HighlightText;
