export default function IconSearch({ className }: { className?: string }) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9999 4.1189C7.17804 4.1189 4.0791 7.21785 4.0791 11.0397C4.0791 14.8616 7.17804 17.9606 10.9999 17.9606C12.5637 17.9606 14.0064 17.4418 15.1654 16.5669L16.7472 18.1487C17.0157 18.4172 17.451 18.4172 17.7195 18.1487C17.988 17.8803 17.988 17.445 17.7195 17.1765L16.1763 15.6333C17.2616 14.4113 17.9207 12.8024 17.9207 11.0397C17.9207 7.21785 14.8218 4.1189 10.9999 4.1189ZM5.4541 11.0397C5.4541 7.97724 7.93744 5.4939 10.9999 5.4939C14.0624 5.4939 16.5457 7.97724 16.5457 11.0397C16.5457 14.1022 14.0624 16.5856 10.9999 16.5856C7.93744 16.5856 5.4541 14.1022 5.4541 11.0397Z"
        fill="black"
        fillOpacity="0.95"
      />
    </svg>
  );
}
