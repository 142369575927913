interface IProps {
  className?: string;
}

export default function NoData({ className }: IProps) {
  return (
    <div className={`flex justify-center items-center ${className}`}>
      <p className="text-#000/70 text-14px leading-21px">没有搜索到内容</p>
    </div>
  );
}
