import Link from 'next/link';

import ClientLink from './Header/ClientLink';

export default function AppLogo({ type = 'server' }: { type?: NextComponentType }) {
  return type === 'server' ? (
    <Link href="/">
      <h1 className="w-[104px] h-[40px] pl-[52px] bg-[url(/img/logo.svg)] bg-left  bg-no-repeat cursor-pointer" />
    </Link>
  ) : (
    <ClientLink href="/">
      <h1 className="w-[104px] h-[40px] pl-[52px] bg-[url(/img/logo.svg)] bg-left  bg-no-repeat cursor-pointer" />
    </ClientLink>
  );
}
