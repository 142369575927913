import Link from 'next/link';

import { Avatar, VolListCoverCard, CollectButton, IconComment } from '@/components';

export default function JournalItem({
  journalNo,
  id,
  title,
  image,
  summary,
  commentList,
  haveCollect,
  totalCommentReply,
  userCollectCount,
}: JournalInfo) {
  return (
    <div className="flex flex-col w-[712px] rounded-[6px] bg-base ">
      <Link href={`/vol/${journalNo}`}>
        <VolListCoverCard img={image} title={title} no={journalNo} />
      </Link>

      {/* 摘要 */}
      <Link href={`/vol/${journalNo}`}>
        <p className="w-full mt-[15px] mb-[12px] text-[15px] leading-[24px] font-500 text-base cursor-pointer text-overflow-2">
          {summary}
        </p>
      </Link>

      {/* 精选评论 */}
      {!!commentList.length && (
        <Link className="flex flex-row items-center mb-18px" href={`/vol/${journalNo}#comment`}>
          <div className={`flex flex-row relative w-25px h-15px mr-9px`}>
            {commentList?.[0]?.avatar && <Avatar className="absolute" size={15} src={commentList[0].avatar} />}
            {commentList?.[1]?.avatar && (
              <div className=" flex justify-center items-center absolute left-[9px] w-[16px] h-[16px] rounded-50% bg-[#fff] overflow-hidden">
                <Avatar size={15} src={commentList[1].avatar} />
              </div>
            )}
          </div>

          <p className="w-270px text-14px leading-15px text-[rgba(0,0,0,0.7)] text-overflow cursor-pointer">
            {commentList[0].content}
          </p>
        </Link>
      )}

      <div className="flex flex-row items-center">
        {/* 评论按钮 */}
        <Link className="flex flex-row items-center mr-24px" href={`/vol/${journalNo}#comment`}>
          <IconComment count={totalCommentReply} />
        </Link>

        {/* 收藏期刊 */}
        <CollectButton
          showText
          iconPosition="left"
          id={id}
          active={haveCollect}
          count={userCollectCount}
          collectType="1"
          gap={6}
          textClassName="w-[42px] ml-[6px] text-[14px] leading-[16px]"
        />
      </div>
    </div>
  );
}
