'use client';

import { useShallow } from 'zustand/react/shallow';

import { useToast } from '@/components/ui/use-toast';

import { apiGetSongRandom } from '@/services';
import { useAudioStore } from '@/store';

export default function ButtonFM() {
  const { toast } = useToast();
  const {
    playListInfo,
    setPlayListInfo,
    setPlayList,
    setAudioId,
    setPlayQueue,
    setPlayOrder,
    setShowCard,
    setPlayState,
  } = useAudioStore(
    useShallow((state) => ({
      playState: state.playState,
      playListInfo: state.playListInfo,
      setPlayListInfo: state.setPlayListInfo,
      setAudioId: state.setAudioId,
      setPlayState: state.setPlayState,
      setPlayList: state.setPlayList,
      setPlayQueue: state.setPlayQueue,
      setPlayOrder: state.setPlayOrder,
      setShowCard: state.setShowCard,
    })),
  );

  /**
   * 1. 在 random 接口拿 30 首歌
   * 2. 全部加到 playList 内
   * 3. 将第一首加入到 queue
   * 4. 调整为 顺序播放
   * 4. 播放第一首歌
   */
  const handlePlayFM = async () => {
    if (playListInfo.type === 'fm') {
      setShowCard(true);
      // if (!playState) setPlayState(true);
      return;
    }

    const res = await apiGetSongRandom(30);

    if (res.code === 200 && res.data.length > 0) {
      const setRes = setPlayListInfo({
        type: 'fm',
        id: 'fm',
      });
      if (!setRes) return;
      setPlayList(res.data.slice(1));
      setPlayOrder('list_loop');
      setPlayQueue([res.data[0]]);
      setAudioId(res.data[0].id);
      setShowCard(true);
      setPlayState(true);
    } else {
      toast({
        description: `播放失败，请稍后再试`,
        duration: 1500,
        type: 'background',
      });
    }
  };

  return (
    <button className="flex items-center w-fit h-24px bg-left-center cursor-pointer group" onClick={handlePlayFM}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-3px">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          strokeLinejoin="round"
          className="fill-#000/95 group-hover:fill-brand"
          d="M12.7555 5.29102C13.058 5.18671 13.3933 5.28579 13.5906 5.53774L17 9.89314L20.4094 5.53774C20.6067 5.28579 20.942 5.18671 21.2445 5.29102C21.547 5.39534 21.75 5.68007 21.75 6.00004V13C21.75 13.4143 21.4142 13.75 21 13.75C20.5858 13.75 20.25 13.4143 20.25 13V8.17487L17.5906 11.5722C17.4484 11.7538 17.2306 11.8599 17 11.8599C16.7694 11.8599 16.5516 11.7538 16.4094 11.5722L13.75 8.17487V13C13.75 13.4143 13.4142 13.75 13 13.75C12.5858 13.75 12.25 13.4143 12.25 13V6.00004C12.25 5.68007 12.453 5.39534 12.7555 5.29102ZM11.8529 17.2646C11.95 17.2452 12.05 17.2452 12.1471 17.2646L17.1471 18.2646C17.5533 18.3458 17.8167 18.741 17.7354 19.1471C17.6542 19.5533 17.2591 19.8167 16.8529 19.7355L12 18.7649L7.14709 19.7355C6.74092 19.8167 6.3458 19.5533 6.26456 19.1471C6.18333 18.741 6.44674 18.3458 6.85291 18.2646L11.8529 17.2646ZM3 5.25004C2.58579 5.25004 2.25 5.58583 2.25 6.00004V9.42679V13C2.25 13.4143 2.58579 13.75 3 13.75C3.41421 13.75 3.75 13.4143 3.75 13V10.1768H9C9.41421 10.1768 9.75 9.84101 9.75 9.42679C9.75 9.01258 9.41421 8.67679 9 8.67679H3.75V6.75004H10C10.4142 6.75004 10.75 6.41426 10.75 6.00004C10.75 5.58583 10.4142 5.25004 10 5.25004H3Z"
        />
      </svg>

      <div className="text-#000/95 group-hover:text-brand text-15px leading-24px">电台</div>
    </button>
  );
}
