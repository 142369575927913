import serverHttp from '@/utils/request/server';

/**
 * @description 查询专栏列表
 * @category 专栏分类
 * @page 页码
 * @size 每页条数
 */
export const apiEssayList: (params: {
  category?: Serv.Essay.Category;
  page?: number;
  size?: number;
}) => Promise<FetchResponse<Paging<EssayDetail>>> = async ({ category = '0', page = 1, size = 10 }) => {
  const request = new Request(`http://localhost`, {
    method: 'GET',
    headers: {
      requestUrl: `/music/essay/search/${category}/${page}/${size}`,
    },
  });
  const res = await serverHttp.get(request);
  return res;
};

/**
 * @description 根据期刊号查询期刊信息
 * @id 期刊号
 */
export const apiGetEssayInfoById: (params: { id: string }) => Promise<FetchResponse<EssayDetail>> = async ({ id }) => {
  const request = new Request('http://localhost', {
    method: 'GET',
    headers: {
      requestUrl: `/music/essay/${id}`,
    },
  });
  const res = await serverHttp.get(request);
  return res;
};
