import MiniJournalCard from './MiniJournalCard';

interface IProps {
  list: JournalInfo[];
  keyword?: string;
  className?: string;
  onClick?: () => void;
}

export default function MiniJournalCardList({ list, keyword, className, onClick }: IProps) {
  return (
    <div className={`grid  grid-cols-4 gap-15px ${className}`}>
      {list.map((item) => (
        <MiniJournalCard
          key={item.id}
          image={item.image}
          journalNo={item.journalNo}
          title={item.title}
          keyword={keyword}
          onClick={onClick}
        />
      ))}
    </div>
  );
}
