import Image from 'next/image';
import Link from 'next/link';

import { HighlightText } from '@/components';

export default function MiniJournalCard({
  image,
  journalNo,
  title,
  keyword,
  onClick,
}: {
  image: string;
  journalNo: string;
  title: string;
  keyword?: string;
  onClick?: () => void;
}) {
  return (
    <Link href={`/vol/${journalNo}`} onClick={onClick}>
      <div className="w-162px h-147px group">
        <Image
          width={162}
          height={100}
          src={image}
          alt={`${title} cover`}
          className="w-162px h-100px rounded-3px group-hover:transform-scale-105 transition-transform-300"
          unoptimized
        />
        <HighlightText
          text={`VOL·${journalNo.toString().padStart(4, '0')}`}
          keyword={keyword ?? ''}
          className="w-full mt-9px text-[rgba(0,0,0,0.4)] text-12px leading-17px text-overflow"
        />
        <HighlightText
          text={title}
          keyword={keyword ?? ''}
          className="w-full mt-1px text-[0,0,0,0.95] text-14px leading-20px text-overflow text-overflow"
        />
      </div>
    </Link>
  );
}
