'use client';

import { useEffect } from 'react';

import { useSearchParams } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import { PlayButton } from '@/components';
import { useAudioStore } from '@/store';

export default function VolPlayButton({
  className,
  journalInfo,
  songList,
}: {
  journalInfo: JournalInfo;
  songList: SongInfo[];
  className?: string;
}) {
  const searchParams = useSearchParams();

  const { playListInfo, playState, setPlayState, setPlayListInfo, setPlayList, setAudioId } = useAudioStore(
    useShallow((state) => {
      return {
        playListInfo: state.playListInfo,
        playState: state.playState,
        setPlayState: state.setPlayState,
        setPlayListInfo: state.setPlayListInfo,
        setPlayList: state.setPlayList,
        setAudioId: state.setAudioId,
      };
    }),
  );

  // 播放器播放列表为当前期刊
  const isCurrentVol = playListInfo.type === 'vol' && playListInfo.id === journalInfo.id;

  const handlePlay = (state: boolean) => {
    // 未在播放当前歌单
    if (state && !isCurrentVol) {
      const res = setPlayListInfo({
        type: 'vol',
        id: journalInfo.id,
      });
      if (!res) return;
      setPlayList(songList);
      setAudioId(songList[0].id);
    }

    // 如果正在播放当前歌单，则直接改变播放状态
    setPlayState(!playState);
  };

  useEffect(() => {
    // 如果路径中有 autoPlay 参数，则播放
    const autoPlay = searchParams.get('autoPlay');
    if (autoPlay) {
      handlePlay(true);
    }
  }, []);

  return (
    <PlayButton
      className={className}
      playState={isCurrentVol && playState}
      size={60}
      onClick={() => handlePlay(!(isCurrentVol && playState))}
    />
  );
}
