export default function CommentIcon({ count }: { count: string }) {
  return (
    <div className="flex flex-row items-center group">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          className="stroke-[#C5C5C5] group-hover:stroke-brand group-hover:stroke-opacity-0 transition-stroke"
          d="M20.5 12C20.5034 13.3199 20.195 14.6219 19.6 15.8C18.8944 17.2117 17.8097 18.3992 16.4674 19.2293C15.1251 20.0594 13.5782 20.4994 12 20.5C10.6801 20.5034 9.37812 20.195 8.20001 19.6L4.10714 19.8929L4.40003 15.8C3.80496 14.6219 3.49659 13.3199 3.50003 12C3.50064 10.4218 3.94064 8.87486 4.77074 7.53257C5.60085 6.19027 6.78827 5.10559 8.20001 4.40003C9.37812 3.80496 10.6801 3.49659 12 3.50003H12.5C14.5843 3.61502 16.553 4.49479 18.0291 5.97088C19.5052 7.44697 20.385 9.41566 20.5 11.5V12Z"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span className="ml-[6px] text-[14px] leading-[16px] text-[rgba(0,0,0,0.4)] group-hover:text-brand">{count}</span>
    </div>
  );
}
