import Link from 'next/link';

export default function Category({
  className,
  list,
  current,
  path,
  itemKey,
}: {
  className?: string;
  list: CategoryType[];
  current: Serv.Essay.Category | string;
  path: string;
  itemKey: string;
}) {
  return (
    <div
      className={`flex items-center gap-x-18px gap-y-8px mr-[-8px] flex-wrap w-full p-24px bg-#F2F3F7 rounded-6px ${className}`}
    >
      {list.map((item: CategoryType) => (
        <Link
          href={`${path}/${item[itemKey as keyof typeof item]}`}
          key={item.id}
          className={`w-fit h-18px mr-8px text-13px leading-18px text-center cursor-pointer ${current === item.id ? 'text-brand' : 'text-#000/90 hover:text-brand'}`}
        >
          {item.nameCh}
        </Link>
      ))}
    </div>
  );
}
