import { produce } from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';

// import { openLogin } from '@/components/Login/LoginContext';

import { apiGetSongRandom } from '@/services';
// import { clearAccessToken, checkAuthOffline } from '@/utils';

interface AuioState {
  /** 播放状态 true: 播放 false: 暂停 */
  playState: boolean;
  /** 播放顺序 list_loop: 列表循环 random: 随机 single: 单曲 */
  playOrder: PlayOrder;
  /** 展示播放卡片 true: 显示 false: 隐藏 */
  showCard: boolean;
  /** 当前音频id */
  audioId: string;
  /** 播放列表信息 */
  playListInfo: IPlayListInfo;
  /** 播放列表 */
  playList: SongInfo[];
  /** 播放队列 */
  playQueue: SongInfo[];
  /** 音量 */
  volume: number;
  setVolume: (volume: number) => void;
  /** 静音前保存的音量 */
  preMuteVolume: number;
  setPreMuteVolume: (volume: number) => void;
  /** 展示播放器 */
  useShowPlayer: () => boolean;
  setPlayType: (type: string) => void;
  setPlayState: (state: boolean) => void;
  setPlayOrder: (playOrder: PlayOrder) => void;
  setAudioId: (id: string) => void;
  // 显示/隐藏播放器
  setShow: (value: boolean) => void;
  // 显示/隐藏单曲卡片
  setShowCard: (value: boolean) => void;
  setPlayListInfo: (info: IPlayListInfo) => boolean;
  // 设置播放列表
  updatePlayList: (list: SongInfo[]) => void;
  // 设置播放列表
  setPlayList: (list: SongInfo[]) => void;
  setPlayQueue: (list: SongInfo[]) => void;
  // 切换歌曲 -1: 上一首 1: 下一首
  switchAudio: (diff: number) => void;
}

const useAudioState = create<AuioState>()(
  devtools(
    persist(
      (set, get) => {
        // 设置音频id
        const setAudioId = (id: string) => {
          if (!id) return;
          if (id === get().audioId) return; // 传入为当前正在播放的音频 id
          if (get().playQueue.findIndex((item) => item.id === id) < 0) return; // 不在歌单内

          set(
            produce((state) => {
              state.audioId = id;
            }),
          );
        };

        // 切换歌曲
        const switchAudio = async (diff: number) => {
          if (get().playListInfo.type === 'fm') {
            await switchFMSong(diff);
          } else {
            switchSongDefault(diff);
          }
        };

        // 常规模式 切换歌曲
        const switchSongDefault = (diff: number) => {
          if (diff === 0) return;
          const playQueue = get().playQueue;
          if (!playQueue) return;

          const index = playQueue.findIndex((item) => item.id === get().audioId);
          const nextIndex = index + diff; // 计算后的 index

          // 判断 index 是否越界,计算音频 id
          let audioId = '';
          if (nextIndex < 0) {
            audioId = playQueue[playQueue.length - 1].id;
          } else if (nextIndex >= playQueue.length) {
            audioId = playQueue[0].id;
          } else {
            audioId = playQueue[nextIndex].id;
          }

          setAudioId(audioId);
        };

        // FM 模式，切换歌曲
        const switchFMSong = async (diff: number) => {
          if (diff === 0) return;
          let { playQueue, playList } = get();
          if (!playQueue) return;

          // 获取当前播放歌曲, 在 Queue 中的 index，并计算出下一首歌曲的 index
          const queueIndex = playQueue.findIndex((item) => item.id === get().audioId);
          const nextQueueIndex = queueIndex + diff;

          // 播放 list 最后一首歌时，再加载30首
          if (playList.length < 3) {
            await getFm();
            playQueue = get().playQueue;
            playList = get().playList;
          }

          // 切换歌曲
          let audioId = '';
          if (nextQueueIndex < 0) {
            // 在第一首歌点击上一首，切换到最后一首
            audioId = playQueue[playQueue.length - 1]?.id;
          } else if (nextQueueIndex >= playQueue.length) {
            // 在最后一首歌点击下一首，往 Queue 中 push 歌曲
            const newAudio = playList[0];
            const newQueue = playQueue.length > 50 ? [...playQueue.slice(1), newAudio] : [...playQueue, newAudio];

            set(
              produce((state) => {
                state.playQueue = newQueue;
                state.playList = playList.slice(1);
              }),
            );
            audioId = newAudio?.id;
          } else {
            // 非越界切换
            audioId = playQueue[nextQueueIndex]?.id;
          }

          // 切换歌曲
          if (audioId) setAudioId(audioId);
        };

        // 切换播放顺序
        const setPlayOrder = (playOrder: PlayOrder) => {
          const playList = cloneDeep(get().playList);
          let queue: SongInfo[];
          switch (playOrder) {
            case 'list_loop':
              queue = playList;
              break;
            case 'random':
              queue = playList.sort(() => Math.random() - 0.5);
              break;
            case 'single_loop':
              queue = [playList[get().playList.findIndex((item) => item.id === get().audioId)]];
              break;
          }
          set(
            produce((state) => {
              state.playOrder = playOrder;
              state.playQueue = queue;
            }),
          );
        };

        // 显示播放器
        const useShowPlayer = () => {
          return !!(get().audioId || get().showCard);
        };

        // 获取 fm 列表
        const getFm = async () => {
          const res = await apiGetSongRandom(30);
          const { playList } = get();

          if (res.code === 200 && res.data.length > 0) {
            const newList = [...playList, ...res.data];
            set(
              produce((state) => {
                state.playList = newList;
              }),
            );
          }
        };

        return {
          audioId: '',
          playOrder: 'list_loop',
          playState: false,
          playListInfo: {
            type: 'vol',
            id: '',
          },
          show: false,
          showCard: false,
          playList: [],
          playQueue: [],
          volume: 100,
          setVolume: (value) =>
            set(
              produce((state) => {
                state.volume = value;
              }),
            ),
          preMuteVolume: 100,
          setPreMuteVolume: (value) =>
            set(
              produce((state) => {
                state.preMuteVolume = value;
              }),
            ),
          useShowPlayer,
          setAudioId,
          setPlayOrder,
          setPlayType: (value) =>
            set(
              produce((state) => {
                state.playType = value;
              }),
            ),
          setPlayState: (value) =>
            set(
              produce((state) => {
                state.playState = value;
              }),
            ),
          setShow: (value) =>
            set(
              produce((state) => {
                state.show = value;
              }),
            ),
          setShowCard: (value) =>
            set(
              produce((state) => {
                state.showCard = value;
              }),
            ),
          setPlayListInfo: (list) => {
            // const auth: boolean = checkAuthOffline();
            // if (!auth) {
            //   clearAccessToken();
            //   openLogin();
            //   return false;
            // }

            set(
              produce((state) => {
                state.playListInfo = list;
              }),
            );
            return true;
          },
          updatePlayList: (list) => {
            set(
              produce((state) => {
                state.playList = list;
              }),
            );
          },
          setPlayList: (list) => {
            set(
              produce((state) => {
                state.playList = list;
                state.playOrder = 'list_loop';
                state.playQueue = list;
              }),
            );
            return true;
          },
          setPlayQueue: (list) => {
            set(
              produce((state) => {
                state.playQueue = list;
              }),
            );
          },
          switchAudio,
        };
      },
      {
        name: 'audio',
        storage: createJSONStorage(() => localStorage),
      },
    ),
    {
      name: 'audio', // redux-devtools 中的 store 名称
      enabled: process.env.NEXT_PUBLIC_NODE_ENV !== 'production', // 是否开启 redux-devtools
    },
  ),
);

export default useAudioState;
