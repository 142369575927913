'use client';

// import Link from 'next/link';

import { toast } from '../ui/use-toast';

import { platformList } from './data';

export default function FooterPlatform({ className }: { className?: string }) {
  // const { toast } = useToast();

  const handleClick = () => {
    toast({ description: '敬请期待', duration: 1500 });
  };

  return (
    <div className={`w-auto text-[rgba(255,255,255,0.6)] ${className}`}>
      <h3 className="text-[12px] leading-[17px]">雀乐开放平台</h3>
      <ul className="mt-[24px]">
        {platformList.map((item, index) => (
          <li
            key={index}
            className="mb-[9px] text-[12px] leading-[17px] hover:text-[rgba(255,255,255,0.9)] transition-colors cursor-pointer"
            onClick={handleClick}
          >
            {/* <Link
              className="text-[12px] leading-[17px] hover:text-[rgba(255,255,255,0.9)] transition-colors"
              href={item.url}
              onClick={handleClick}
            >
              {item.name}
            </Link> */}

            {item.name}
          </li>
        ))}
      </ul>
    </div>
  );
}
