import clientHttp from '@/utils/request/client';

/**
 * @description 发送短信验证码
 * @id 手机号
 * @deviceId 设备id
 * @countryCode 国家码，默认为‘+86’
 * @imageCheckCode 图形验证码
 */
export const apiSendSMS = async (params: {
  mobile: string;
  deviceId: string;
  countryCode?: string;
  imageCheckCode?: string;
}) => {
  const result: FetchResponse<string> = await clientHttp.post('/queyueapi/user/user/sendsms', params);
  return result;
};

/**
 * @description 登录/注册
 * @id 手机号
 * @deviceId 设备id
 * @countryCode 国家码，默认为‘+86’
 * @imageCheckCode 图形验证码
 */
export const apiUserLogin = async (params: {
  mobile: string;
  mobileCheckCode: string;
  deviceId: string;
  deviceBrand?: string;
}) => {
  const result: FetchResponse<string> = await clientHttp.post('/queyueapi/user/user/appLogin', params);
  return result;
};

/**
 * @description token 续期
 */
export const apiAutoLogin = async (params: { deviceId: string; deviceBrand?: string }) => {
  const result: FetchResponse<string> = await clientHttp.post('/queyueapi/user/user/autoLogin', params);
  return result;
};

/**
 * @description 退出登录
 */
export const apiUserLogout = async (token: string) => {
  fetch('/queyueapi/user/user/logout', {
    headers: {
      Authorization: token,
    },
    method: 'GET',
  });
};

/**
 * @description 获取个人信息
 */
export const apiGetMyUserInfo = async () => {
  const res: FetchResponse<UserInfo> = await clientHttp.get('/queyueapi/user/my/userInfo');
  return res;
};

/**
 * @description 获取UUID
 */
export const apiGetUUID = async () => {
  const res: FetchResponse<string> = await clientHttp.get('/queyueapi/user/user/uuid');
  return res;
};

/**
 * @description 校验uuid对应的请求认证状态
 */
export const apiCheckQr = async (uuid: string) => {
  const res: FetchResponse<string> = await clientHttp.get(`/queyueapi/user/user/check/qr?uuid=${uuid}`);
  return res;
};

/**
 * @description 获取登录二维码
 */
export const apiGetLoginQr = async (uuid: string) => {
  const res: FetchResponse<string> = await clientHttp.get(`/queyueapi/user/user/getLoginQr?uuid=${uuid}`);
  return res;
};
