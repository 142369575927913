import Image from 'next/image';

interface Props {
  src: string;
  alt?: string;
  size: number;
  className?: string;
}

const Avatar = ({ src, alt = 'avatar', size, className = '' }: Props) => {
  return (
    <div className={`rounded-full bg-[#fff] overflow-hidden ${className}`} style={{ width: size, height: size }}>
      <Image className="w-full h-full object-cover" width={size} height={size} src={src} alt={alt} unoptimized />
    </div>
  );
};

export default Avatar;
