'use client';
/**
 * 待优化：
 *  1. 拖动滚动条时音轨不要动，等拖动完毕后再动
 */
import { useState, useRef, useEffect } from 'react';

import throttle from 'lodash/throttle';
import Image from 'next/image';
import { useShallow } from 'zustand/react/shallow';

import { useToast } from '@/components/ui/use-toast';

import styles from './index.module.css';
import NextButton from './widget/Next';
import OrderButton from './widget/Order';
import ProgressBar from './widget/ProgressBar';
import VolumeButton from './widget/Volume';

import { CollectButton, PlayButton } from '@/components';
import { useAudioStore } from '@/store';
import { secondToDate } from '@/utils';

export default function AudioPlayer({
  className,
  onSwitchShowCard,
}: {
  className?: string;
  onSwitchShowCard: () => void;
}) {
  const { toast } = useToast();
  const {
    playState,
    playListInfo,
    audioId,
    playOrder,
    playQueue,
    showCard,
    setPlayState,
    switchAudio,
    setPlayOrder,
    volume,
    setVolume,
  } = useAudioStore(
    useShallow((state) => {
      return {
        playList: state.playList,
        playState: state.playState,
        playListInfo: state.playListInfo,
        audioId: state.audioId,
        playOrder: state.playOrder,
        playQueue: state.playQueue,
        showCard: state.showCard,
        setPlayListInfo: state.setPlayListInfo,
        setPlayState: state.setPlayState,
        setPlayOrder: state.setPlayOrder,
        switchAudio: state.switchAudio,
        volume: state.volume,
        setVolume: state.setVolume,
      };
    }),
  );

  const audio: SongInfo = playQueue.find((item) => item?.id === audioId) || playQueue[0];

  const audioRef = useRef(new Audio(''));
  const isReady = useRef<boolean>(false); // 是否加载过组件
  const isDrag = useRef<boolean>(false); // 是否拖动进度条

  const [trackProgress, setTrackProgress] = useState<number>(0); // 音频进度(s)
  const [duration, setDuration] = useState<number>(0); // 音频总时长(s)

  /**
   * @description 切换播放顺序
   * 1. 找到下一个 order
   * 2. 传到 store，store 中的方法会调整 playQueue
   * 3. 提示 已切换至xxx
   */
  const handleChangeOrder = () => {
    const orderArr: Array<{ name: string; value: PlayOrder }> = [
      { name: '列表循环', value: 'list_loop' },
      { name: '随机播放', value: 'random' },
      { name: '单曲循环', value: 'single_loop' },
    ];
    const index = orderArr.findIndex((item) => item.value === playOrder);
    const newOrder = orderArr[(index + 1) % 3];

    setPlayOrder(newOrder.value);

    toast({
      description: `已切换至${newOrder.name}`,
      duration: 1500,
      type: 'background',
    });
  };

  /**
   * @description 切换音乐
   * @param direction: -1: 上一首  1: 下一首
   */
  const handleSwitchAudio = async (direction: number) => {
    if ((playListInfo.type !== 'fm' && playQueue.length === 1) || playOrder === 'single_loop') {
      /**
       * 重置歌曲进度
       * 场景1: 单曲循环
       * 场景2: 非单曲循环或fm 模式，且队列里只有一首歌时，重置歌曲进度
       */
      audioRef.current.currentTime = 0;
    } else {
      // 切换歌曲
      await switchAudio(direction);
    }
  };

  // 调整播放进度
  const handleChangeProgress = (value: number) => {
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
    setPlayState(true);
  };

  // 监听音频进度
  const listenTimeUpdate = () => {
    const currentTime = Math.ceil(audioRef.current.currentTime) ?? 0;

    // 完成播放
    if (audioRef.current.ended || currentTime >= audioRef.current.duration) {
      handleSwitchAudio(1);
    }

    if (!isDrag.current) {
      setTrackProgress(currentTime);
    }
  };

  // 设置 mediaSession
  const setMediaSession = () => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: audio.title,
        artist: audio.artist,
        album: audio.album,
        artwork: [{ src: audio.pic }],
      });

      const actionHandlers: [string, ({ ...args }: any) => void][] = [
        ['play', () => setPlayState(true)],
        ['pause', () => setPlayState(false)],
        ['previoustrack', () => handleSwitchAudio(-1)],
        ['nexttrack', () => handleSwitchAudio(1)],
        [
          'seekto',
          ({ seekTime }: { seekTime?: number }) => {
            if (seekTime) handleChangeProgress(seekTime);
          },
        ],
      ];

      for (const [action, handler] of actionHandlers) {
        try {
          navigator.mediaSession.setActionHandler(action as MediaSessionAction, handler as () => void);
        } catch (error) {
          console.log(`The media session action "${action}" is not supported yet.`);
        }
      }
    }
  };

  // 调节音量
  const handleChangeVolumn = (value: number) => {
    setVolume(value);
  };

  const handleSetVolume = (value: number) => {
    audioRef.current.volume = value / 100;
  };

  // 调节音量
  useEffect(() => {
    handleSetVolume(volume);
  }, [volume]);

  const loadAudio = async (url: string) => {
    try {
      // 设置Audio对象的源为音频文件的URL
      audioRef.current = new Audio(url);

      // 等待音频加载完成
      await new Promise((resolve, reject) => {
        audioRef.current.oncanplaythrough = resolve;
        audioRef.current.onerror = reject;
      });
    } catch (error) {
      console.error('Error downloading and playing audio:', error);
    }
  };

  const initAudioMeta = () => {
    setMediaSession();
    handleSetVolume(volume);
    setDuration(audioRef.current.duration);
    audioRef.current.addEventListener('timeupdate', listenTimeUpdate);
  };

  const switchNewAudio = async () => {
    if (!audio?.src) return;

    handleGC();
    await loadAudio(audio.src); // 加载歌曲
    initAudioMeta(); // 初始化 meta 数据

    if (isReady.current) {
      setPlayState(true);
      audioRef.current.play();
    } else {
      isReady.current = true;
    }
  };

  // 切换歌曲
  useEffect(() => {
    switchNewAudio();
  }, [audioId]);

  useEffect(() => {
    playState ? audioRef.current.play() : audioRef.current.pause();
  }, [playState]);

  // 垃圾处理
  const handleGC = () => {
    audioRef.current.pause(); // 这里必须要先暂停, 才能触发垃圾回收
    audioRef.current.src = ''; // 断开音频文件的源
    audioRef.current.load(); // 释放音频文件的资源
    audioRef.current.removeEventListener('timeupdate', listenTimeUpdate);
  };

  useEffect(() => {
    setPlayState(false); // 覆盖本地的状态

    return () => {
      handleGC();
    };
  }, []);

  return (
    <div className={`flex justify-between w-1200px h-full ${className}`}>
      {/* left */}
      <div className="flex flex-row w-fit h-fit mt-25px">
        {/* 专辑封面 */}
        <div
          className={`relative flex-shrink-0 w-48px h-48px rounded-3px bg-[#000] overflow-hidden cursor-pointer ${showCard ? styles.album_pic_overlay_collapse : styles.album_pic_overlay_expand}`}
          onClick={onSwitchShowCard}
        >
          {audio?.pic && (
            <Image src={audio.pic} alt="music" width={48} height={48} unoptimized className="w-full h-full" />
          )}
        </div>

        {/* 歌曲信息 */}
        <div className="w-183px ml-15px cursor-default">
          <p className="mt-4px text-15px leading-21px text-base text-overflow">{audio?.title ?? ''}</p>
          <p className="text-12px leading-17px mt-4px text-[rgba(0,0,0,0.7)] text-overflow">{`${audio?.artist}/${audio?.album}`}</p>
        </div>
      </div>

      <div className="flex items-center h-full pt-10px">
        {/* 上一首、播放、下一首 */}
        <div className="flex items-center gap-18px h-fit mx-24px">
          <NextButton className="rotate-180" onClick={throttle(() => handleSwitchAudio(-1), 200)} />
          <PlayButton playState={playState} size={38} onClick={throttle(() => setPlayState(!playState), 200)} />
          <NextButton onClick={throttle(() => handleSwitchAudio(1), 200)} />
        </div>

        <div className="flex items-center justify-between h-fit">
          {/* 播放时长 */}
          <span className="block w-27px text-11px leading-14px text-right text-#000">
            {secondToDate(trackProgress)}
          </span>

          {/* 进度条 */}
          <ProgressBar
            currentTime={trackProgress}
            duration={duration}
            onChange={(value: number) => setTrackProgress(value)}
            onCommit={(value: number) => handleChangeProgress(value)}
            onChangeDraging={(value: boolean) => {
              isDrag.current = value;
            }}
            className="w-548px ml-6px mr-14px"
          />

          {/* 总时长 */}
          <span className="block w-27px text-11px leading-14px text-left text-#000">{secondToDate(duration || 0)}</span>
        </div>
      </div>

      {/* right */}
      <div className="flex items-end h-full ml-60px pb-28px">
        <div className="flex h-full items-end h-fit gap-18px">
          {/* 收藏歌曲 */}
          <CollectButton
            key={audio?.id ?? '0'}
            id={audio?.id ?? '0'}
            active={audio?.haveCollect ?? false}
            collectType="0"
            color="dark"
          />

          {/* 播放顺序 */}
          <OrderButton
            order={playOrder}
            onClick={handleChangeOrder}
            className={`${playListInfo.type === 'fm' && 'hidden'}`}
          />
          <VolumeButton value={volume} onChange={handleChangeVolumn} />
        </div>
      </div>
    </div>
  );
}
