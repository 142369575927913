'use client';

import { useEffect, useState } from 'react';

import styles from './index.module.css';

export default function ScrollTopButton({ className }: { className: string }) {
  const [show, setShow] = useState<boolean>(false);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const listener = () => {
    if (window.scrollY > 4400) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  return (
    <div
      className={`${show ? 'block' : 'hidden'} w-[56px] h-[56px] p-[16px] rounded-[2px] bg-[#F2F3F7] bg-[url(/img/icon/scroll-top.svg)] bg-no-repeat bg-center cursor-pointer  ${className} ${styles.hover_mask}`}
      onClick={handleScrollTop}
    />
  );
}
