'use client';

import { useEffect } from 'react';

import { usePathname, useSearchParams } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import { useUserStore } from '@/store';
import { checkAuthOffline } from '@/utils';

export default function RedirectCheck() {
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const { setShowLogin } = useUserStore(
    useShallow((state) => ({
      setShowLogin: state.setShowLogin,
    })),
  );

  useEffect(() => {
    if (typeof window === 'undefined') return; // 不在浏览器环境

    const serach = new URLSearchParams(window.location.search);
    const redirect = serach.get('redirect');

    if (redirect && !checkAuthOffline()) {
      setShowLogin(true);
      return;
    }
  }, [pathName, searchParams, typeof window !== 'undefined' ? window.location.search : '']);

  return <></>;
}
