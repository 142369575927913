'use client';

import { useState } from 'react';

import CommentForm from './MainComment/CommentForm';
import CommentHeader from './MainComment/CommentHeader';
import CommentList from './MainComment/CommentList';

import { apiCommentSave } from '@/services';

interface Props {
  journalId: string;
  className: string;
  totalCommentReply: string;
  type: Serv.Comment.Type;
}

export default function Comment({ journalId, className, totalCommentReply, type }: Props) {
  const [commentType, setCommentType] = useState<'hot' | 'new'>('hot');
  const [newCommentList, setNewCommentList] = useState<Comment[]>([]); // 新发送的评论

  // 切换热门/最新
  const handleChangeType = async (type: 'hot' | 'new') => {
    setCommentType(type);
  };

  // 发表评论
  const handleSubmit = async (content: string) => {
    const res = await apiCommentSave({ journalId, parentId: '', content, type });
    if (res.code === 200) {
      setNewCommentList((val) => [res.data, ...val]);
    }
    return res.code === 200;
  };

  return (
    <div className={className} id="comment">
      {/* 一级评论表单 */}
      <CommentForm onSubmit={handleSubmit} />

      {/* header */}
      <CommentHeader
        type={commentType}
        count={totalCommentReply}
        onChangeType={(type) => handleChangeType(type)}
        clssName="mt-25px mb-15px"
      />

      {/* 评论列表 */}
      <CommentList journalId={journalId} commentType={commentType} newCommentList={newCommentList} type={type} />
    </div>
  );
}
