export default function CommentContent({
  nickName,
  publishTime,
  location,
  content,
}: {
  nickName: string;
  publishTime: string;
  location: string;
  content: string;
}) {
  return (
    <div>
      <div className="text-[rgba(0,0,0,0.7)] text-[13px] leading-[18.2px]">{nickName}</div>
      <div className="mt-[4px] text-[rgba(0,0,0,0.4)] text-[10px] leading-[14px]">{`${publishTime} ${location}`}</div>
      <div className="mt-[6px] w-[657px] text-base text-[14px] leading-[20px]">{content}</div>
    </div>
  );
}
